import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import jwt_decode from 'jwt-decode'

import { setToken } from './features/auth/authSlice'

// import Layout from './components/Pages/Layout'
// import Authentication from './components/Pages/Authentication'
import Load from './components/Pages/Load'
import AppInfo from './components/Pages/AppInfo'
import Header from './components/layout/Header/Header'
import Footer from './components/layout/Footer/Footer'
import Login from './components/Pages/Login/Login'
import Registration from './components/Pages/Registration/Registration'
import Dashboard from './components/Pages/Dashboard/Dashboard'
import Upskill from './components/Pages/Upskill/Upskill'
import Work from './components/Pages/Work/Work'
import WorkDetails from './components/Pages/Work/WorkDetails'
import Profile from './components/Pages/Profile/Profile'
import Discuss from './components/Pages/Discuss/Discuss'
import QuizAttempt from './components/Pages/Upskill/QuizAttempt'
import Coins from './components/Pages/Coins/Coins'
import Resume from './components/Pages/Resume/Resume'
import DiscussDetails from './components/Pages/Discuss/DiscussDetails'
import Personal from './components/Pages/Resume/Personal'
import Projects from './components/Pages/Resume/Projects'
import Certificates from './components/Pages/Resume/Certificates'
import Jobs from './components/Pages/Resume/Jobs'
import Degrees from './components/Pages/Resume/Degrees'

const App = ({
  authToken,
  setToken
}) => {
  const validateToken = () => {
    if (authToken) {
      return true
    } else {
      const token = localStorage.getItem('token')
      const tokenData = (token) ? jwt_decode(token) : false
      if (tokenData && new Date(tokenData.exp * 1000) > new Date()) {
        if (tokenData.userInfo.hasOwnProperty('ID') && tokenData.userInfo.hasOwnProperty('RoleID')) {
          setToken({ authToken: token })
          return true
        } else {
          localStorage.clear()
          return false
        }
      } else {
        localStorage.clear()
        return false
      }
    }
  }
  const userLoggedIn = validateToken();

  function ResizeWindow() {
    const screenSize = {};
    screenSize.innerWidth = window.innerWidth;
    screenSize.innerHeight = window.innerHeight;
    // console.log(screenSize);
    // setDeviceDetails(screenSize);
    let vh = window.innerHeight * 0.01;
    // console.log(vh, window.innerHeight, window.innerWidth);
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.setAttribute('deviceHight', window.innerHeight);
  }
  
  useEffect(() => {
    window.addEventListener("resize", () => {
      ResizeWindow();
    });
    ResizeWindow();

    return () => {
      window.removeEventListener("resize", () => {
        ResizeWindow();
      });
    }
  }, []);
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        {!userLoggedIn &&
          <Fragment>
            <Route path="*" element={<Auth />} />
            <Route path="load" element={<Load />} />
            <Route path="register" element={<Registration />} />
            <Route path="dashboard" element={<DashboardView />} />
            <Route path="upskill" element={<UpskillView />} />
            <Route path="work" element={<WorkView />} />
            <Route path='workDetails/:id' element={<WorkDetailsView/>} />
            <Route path='profile' element={<ProfileView />} />
            <Route path='discuss' element={<DiscussView />} />
            <Route path='discussDetails/:id' element={<DiscussDetailsView />} />
            <Route path='quizAttempt' element={<QuizAttemptView />} />
            <Route path='coins' element={<CoinsView />} />
            <Route path='resume' element={<ResumeView />} />
            <Route path='editResumePersonal' element={<EditResumePersonalView />} />
            <Route path='editResumeProjects' element={<EditResumeProjectsView />} />
            <Route path='editResumeCertificates' element={<EditResumeCertificatesView />} />
            <Route path='editResumeJobs' element={<EditResumeJobsView />} />
            <Route path='editResumeDegrees' element={<EditResumeDegreesView />} />
            {/* <Route path="getStartedLogin" element={<GetStartedLoginView />} /> */}
          </Fragment>
        }
        {userLoggedIn &&
          <Fragment>
            <Route path="/" element={<AboutApp />} />
            <Route path="appinfo" element={<AboutApp />} />
            <Route path="*" element={<Auth />} />
          </Fragment>
        }
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function Auth() {
  return (
    <>
    {/* // <div className="App pt-5"> */}
      <Login />
      {/* <Authentication></Authentication> */}
    {/* // </div> */}
    </>
  )
}

function AboutApp() {
  return (
    <div className="App">
      {/* <Layout> */}
      <Header></Header>
        <AppInfo></AppInfo>
      <Footer></Footer>
      {/* </Layout> */}
    </div>
  )
}

function DashboardView() {
  return (
    <div className='App'>
      <Header />
      <div className='MainPagesSection HFMain'>
        <Dashboard />
      </div>
      <Footer />
    </div>
  )
}

function UpskillView() {
  return (
    <div className='App'>
      <Header Tabs="Upskill" />
      <div className='MainPagesSection HTFMain'>
        <Upskill />
      </div>
      <Footer />
    </div>
  )
}

function QuizAttemptView() {
  return (
    <div className='App'>
      <div className='MainPagesSection FMain'>
        <QuizAttempt />
      </div>
    </div>
  )
}

function WorkView() {
  return (
    <div className='App'>
      <Header />
      <div className='MainPagesSection HFMain'>
        <Work />
      </div>
      <Footer />
    </div>
  )
}

function ProfileView() {
  return (
    <div className='App'>
      <Header />
      <div className='MainPagesSection HFMain'>
        <Profile />
      </div>
      <Footer />
    </div>
  )
}

function DiscussView(params) {
  return (
    <div className='App'>
      <Header Tabs='Discuss' />
      <div className='MainPagesSection HTFMain'>
        <Discuss />
      </div>
      <Footer />
    </div>
  )
}

function WorkDetailsView() {
  return (
    <div className='App'>
      <Header header='jobdetails' />
      <div className='MainPagesSection HFMain'>
        <WorkDetails />
      </div>
      {/* <Footer /> */}
    </div>
  )
}

function DiscussDetailsView() {
  return (
    <div className='App'>
      <Header header='discussdetails' />
      <div className='MainPagesSection HFMain'>
        <DiscussDetails />
      </div>
    </div>
  )
}

function CoinsView() {
  return (
    <div className='App'>
      <Header header='coins' />
      <div className='MainPagesSection HFMain'>
        <Coins />
      </div>
      <Footer footer='coins' />
    </div>
  )
}

function ResumeView() {
  return (
    <div className='App'>
      <Header header='resume' />
      <div className='MainPagesSection HFMain'>
        <Resume />
      </div>
      <Footer />
    </div>
  )
}

function EditResumePersonalView() {
  return (
    <div className='App'>
      <Header header='resumeEdit' />
      <div className='MainPagesSection HFMain'>
        <Personal />
      </div>
      {/* <Footer footer='resumeEdit' /> */}
    </div>
  )
}

function EditResumeProjectsView () {
  return (

    <div classsName="App">
      <Header header='resumeEdit' />
      <div className='MainPagesSection HFMain'>
        <Projects />
      </div>
      {/* <Footer footer='resumeEdit' /> */}
      
    </div>
  )
}
function EditResumeCertificatesView () {
  return (

    <div classsName="App">
      <Header header='resumeEdit' />
      <div className='MainPagesSection HFMain'>
        <Certificates />
      </div>
      {/* <Footer footer='resumeEdit' /> */}
      
    </div>
  )
}
function EditResumeJobsView () {
  return (

    <div classsName="App">
      <Header header='resumeEdit' />
      <div className='MainPagesSection HFMain'>
        <Jobs />
      </div>
      {/* <Footer footer='resumeEdit' /> */}
      
    </div>
  )
}
function EditResumeDegreesView () {
  return (

    <div classsName="App">
      <Header header='resumeEdit' />
      <div className='MainPagesSection HFMain'>
        <Degrees />
      </div>
      {/* <Footer footer='resumeEdit' /> */}
      
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const {
    authToken
  } = auth

  return {
    authToken
  }
}

const mapDispatchToProps = {
  setToken
}

export default connect(mapStateToProps, mapDispatchToProps)(App)