import React from 'react'
import { Button } from 'react-bootstrap'
import SvgIcon from '../../svg/SvgIcon'
import DiscussCard from './DiscussCard'
import './DiscussCommentCard.css';
import UserInfoTile from './UserInfoTile';

function DiscussCommentCard() {
  return (
    <div className='CommentCardContainer'>
        <Button variant="link">
            <SvgIcon icon='dotsThreeOutlineHorizontal-duotone' size="20" fill='#000000' />
        </Button>

        <div className='CommentCard'>
            <div className='DiscussCardHeader'>
                <UserInfoTile />
                <p className='DiscussAddedTime'>2 hour ago</p>
            </div>
            <p className='DiscussMessage'>Review the invoice for any errors or discrepancies before finalizing and sending it to the vendor for payment processing. Finally, keep records of the invoice for future reference and reconciliation.</p>
            <div className='DiscussionAction'>
                <Button variant='link' size='sm'>
                    <SvgIcon icon='like-duotone' size="20" fill='#000000' />
                    <span className='ms-1' style={{ 'color': '#000000' }}>99</span>
                </Button>
            </div>
        </div>
    </div>
  )
}

export default DiscussCommentCard