import React from 'react'
import SvgIcon from '../../svg/SvgIcon'
import { Button } from 'react-bootstrap'
import '../Comps/QuizCard.css';
import Badges from './Badges';
import ProductModules from './ProductModules';
import { useNavigate } from 'react-router';

function QuizCard({
    data = {'status': 'active'}
}) {
    const navigat = useNavigate();
  return (
    <div className={`quizCard ${data.status}`} onClick={() => navigat('/quizAttempt')}>
        <div className='data'>
            <div className='data-badges'>
                <Badges variant='badge-coins' text="10 Coins" />

                <Badges variant='badge-level' text="Easy" />

                <Badges variant='badge-time' text="2 mins" />
            </div>
            <h5>How to Create invoice in Oracle Cloud?</h5>
            <ProductModules />
        </div>
        <Button variant="link" className="">
            <SvgIcon icon="caret-right" fill='#fff' size="20" />
        </Button>
    </div>
  )
}

export default QuizCard