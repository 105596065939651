import React, { useState } from 'react'
import { Button, Col, Row, Form } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './EditResume.css'
import { connect } from 'react-redux'
import { setActiveResumeEdit, setSubmitStatus } from '../../../features/resumeEdit/resumeSlice'
import SvgIcon from '../../svg/SvgIcon';
import useModalComp from '../../Hooks/useModalComp'
import { useNavigate } from 'react-router';

export const Certificates = ({ 
  activeResumeEdit,
  setActiveResumeEdit,
  setSubmitStatus
}) => {
  const navigat = useNavigate()
  const { openModal, closeModal, ModalJSX } = useModalComp();
  
  const handleOpenConfirmModal = () => {
    openModal("confirm-modal", {
        ModalBodyContent: <div className='ConfirmationQuizModal'>
            <span className='mb-0 text-center'>
              The changes are not saved
              <br />
              <b>Are you Sure Want to Cancel?</b>
            </span>
        </div>,
    });
  };

  const [FromDate, setFromDate] = useState(null);
  const [ToDate, setToDate] = useState(null);

  const [IssuingOrganisationOption, setIssuingOrganisationOption] = useState(null);
  const IssuingOrganisationOptions = [
    { value: 'One', label: 'One' },
    { value: 'Two', label: 'Two' },
    { value: 'Three', label: 'Three' },
  ];

  function SubmitPersonalData(e) {
    e.preventDefault();
    console.log('SubmitPersonalData', e);
  }

  return (
    <div className='container resumeforms projectforms'>
      <div className='activeFormButtons'>
        <div className='buttonContainer'>
          <Button variant='outline-primary'>
            Add New
            <SvgIcon icon='plus-circle-duotone' fill='#1165EF' size="24" />
          </Button>

          <Button variant='primary'>
            Certificate 1
          </Button>

          <Button variant='outline-primary'>
            Certificate 2
          </Button>

          <Button variant='outline-primary'>
            Certificate 3
          </Button>

          <Button variant='outline-primary'>
            Certificate 4
          </Button>

          <Button variant='outline-primary'>
            Certificate 5
          </Button>
        </div>
      </div>

      <div className='formContianer p-2 mt-1'>
        <div className='FormHeader mb-1'>
          <h3>Certificate 1</h3>
          <Button variant='link' size='sm'>
            <SvgIcon icon='x' size="22" />
          </Button>
        </div>
        <Form onSubmit={SubmitPersonalData}>

          <Row className="mb-2">
            <Form.Group as={Col} xs="12" md="12">
              <Form.Label>Certificate Name*</Form.Label>
              <Form.Control
                type="text"
                name="CertificateName"
                placeholder=''
              />
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} xs="12" md="12">
              <Form.Label>Issuing Organisation*</Form.Label>
              <Select
                defaultValue={IssuingOrganisationOption}
                onChange={setIssuingOrganisationOption}
                options={IssuingOrganisationOptions}
              />
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} xs="12" md="12">
              <Form.Label>Certificatial URL*</Form.Label>
              <Form.Control
                type="text"
                name="CertificatialURL"
                placeholder=''
              />
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group className="mb-1 openfor">
              <Form.Check
                reverse
                label="Currently Pursuing"
                type='checkbox'
                id={`reverse-check-1`}
              />
            </Form.Group>
          </Row>


          <Row className="mb-2">
            <Form.Group as={Col} xs="6" md="6">
              <Form.Label>From Date*</Form.Label>
              <DatePicker
                className="form-control w-100"
                placeholderText="dd-MMM-yyyy"
                dateFormat="dd-MMM-yyyy"
                name="FromDate"
                selected={FromDate}
                onChange={(date) => setFromDate(date)}
            />
            </Form.Group>
            <Form.Group as={Col} xs="6" md="6">
              <Form.Label>To Date*</Form.Label>
              <DatePicker
                className="form-control w-100"
                placeholderText="dd-MMM-yyyy"
                dateFormat="dd-MMM-yyyy"
                name="ToDate"
                selected={ToDate}
                onChange={(date) => setToDate(date)}
            />
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} xs="12" md="12">
              <Form.Label>Short Description</Form.Label>
              <Form.Control
                as="textarea"
                name="ShortDescription"
                rows="4"
                placeholder=''
              />
            </Form.Group>
          </Row>


          <div className="section full bg-white d-block d-md-none mobileMainFooter  position-fixed b-0 e-0 s-0" style={{zIndex: 100}}>
            <div className='d-flex gap-2 px-2 py-0 mt-1'>
              <Button variant='link' className='w-100' onClick={() => handleOpenConfirmModal()}>Exit</Button>
              <Button className='w-100' onClick={() => {
                setSubmitStatus('waiting');
                navigat('/resume')
                }}>Save</Button>
            </div>
          </div>
        </Form>
      </div>
      <div className='AddNextButton'>
        <Button variant='ouline-primary'>
          Add New Certificate
          <SvgIcon icon='plus-circle-duotone' fill='#1165EF' size="24" />
        </Button>
      </div>

      {ModalJSX("confirm-modal", {
        showCloseButton: true,
        children: (
            <div className="ModalAction">
                <Button variant="link" className='exitButton' onClick={() => closeModal("confirm-modal")}>
                    No
                </Button>
                <Button variant="primary" onClick={() => {
                    closeModal("confirm-modal");
                }}>
                    Yes
                </Button>
            </div>
        ),
    })}
    </div>
  )
}

const mapStateToProps = ({ resumeData }) => {
  const { activeResumeEdit } = resumeData;
  return {
    activeResumeEdit
  }
}

const mapDispatchToProps = {
  setActiveResumeEdit,
  setSubmitStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(Certificates)