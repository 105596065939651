import React from 'react'
import useSearchInputFilterBtn from '../../Hooks/useSearchInputFilterBtn'
import QCards from '../Comps/QCards';

function Relevant() {
    const { SearchInputFilterBtnContainer } = useSearchInputFilterBtn('Search Content');

    return (
        <>
        <div className='container'>
            <SearchInputFilterBtnContainer />
        </div>
        <div className='d-flex flex-column gap-2'>
            <QCards RelevantCard />
            <QCards RelevantCard />
            <QCards RelevantCard />
        </div>
        </>
    )
}

export default Relevant