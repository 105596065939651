import React from 'react'
import { connect } from 'react-redux';
import '../Profile/Profile.css';
import SvgIcon from '../../svg/SvgIcon';
import { Badge, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';

export const Profile = (props) => {
    const navigate = useNavigate();
  return (
    <div className='profileView'>
        
        <div className='ProfileHead'>
            <div className='profileImageContainer'>
                <img src='/assets/img/sample/photo/1.jpg' alt='img' />
            </div>
            <h1 className='UserName'>Amruta Patil</h1>
            <p>Headline</p>
            <Button variant='link' className='editProfileButton'>
                <SvgIcon icon='pencilSimpleLine-duotone' size="24" />
            </Button>
        </div>

        <div className='JoinedLocation'>
            <p>
                <SvgIcon icon="calendarBlank-duotone" size="18" fill="#757F87" />
                <span>Joined April 2021</span>
            </p>

            <p>
                <SvgIcon icon="mappin-doutone" size="18" fill="#757F87" />
                <span>Mumbai, India</span>
            </p>
        </div>

        <div className='UserProgressNo'>
            <div className='Item'>
                <h3>123</h3>
                <p>Questions</p>
            </div>

            <div className='Item'>
                <h3>456</h3>
                <p>Answers</p>
            </div>

            <div className='Item'>
                <h3>100</h3>
                <p>Coins</p>
            </div>
        </div>

        <div className='userAction'>
            <Button variant='outline-secondary' onClick={() => navigate('/editResumePersonal')}>
                Edit Resume
            </Button>

            <Button variant='primary' onClick={() => navigate('/resume')}>
                View Resume
            </Button>
        </div>

        <div className='userAchievements'>

            <div className='achievements'>
                <div className='icon'>
                    <SvgIcon icon='lightning' fill='#1165EF' size="30" />
                </div>
                <div className='achievementData'>
                    <h4>Quick responder</h4>
                    <p>Responds within ~2h</p>
                </div>
            </div>

            <div className='achievements'>
                <div className='icon'>
                    <SvgIcon icon='sealCheck-duotone' fill='#25D3B0' size="30" />
                </div>
                <div className='achievementData'>
                    <h4>Most loved Answers</h4>
                    <p>More than 400 likes</p>
                </div>
            </div>

        </div>


        <div className='UsersPriority'>
            <h4 className='profileTitle'>Product</h4>
            <div className='priorities'>
                <Badge bg='primary'>Photo Editing</Badge>
                <Badge bg='primary'>Motion Graphics</Badge>
                <Badge bg='primary'>3D Design</Badge>
            </div>
        </div>

        <div className='UsersPriority'>
            <h4 className='profileTitle'>Track</h4>
            <div className='priorities'>
                <Badge bg='primary'>Photo Editing</Badge>
                <Badge bg='primary'>Motion Graphics</Badge>
                <Badge bg='primary'>3D Design</Badge>
            </div>
        </div>

        <div className='UsersPriority'>
            <h4 className='profileTitle'>Modules</h4>
            <div className='priorities'>
                <Badge bg='primary'>Photo Editing</Badge>
                <Badge bg='primary'>Motion Graphics</Badge>
                <Badge bg='primary'>3D Design</Badge>
                <Badge bg='primary'>Photo Editing</Badge>
                <Badge bg='primary'>Motion Graphics</Badge>
                <Badge bg='primary'>3D Design</Badge>
            </div>
        </div>

        <div className='UserContactDetails'>
            <h4 className='profileTitle'>Links</h4>

            <div className='links'>
                <a href='#' target='_blank'>
                    <SvgIcon icon="phoneCall-duotone" size="18" fill='#787878' />
                    <span>+91 1234567890</span>
                </a>

                <a href='#' target='_blank'>
                    <SvgIcon icon="envelopeSimpleOpen-duotone" size="18" fill='#787878' />
                    <span>rajeshjayant@gmail.com</span>
                </a>

                <a href='#' target='_blank'>
                    <SvgIcon icon="linkedInLogo-duotone" size="18" fill='#787878' />
                    <span>www.linkedin.com/username</span>
                </a>
            </div>
        </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)