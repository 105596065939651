import React, { useState } from 'react'
import './SortBy.css'
import SvgIcon from '../../svg/SvgIcon'

function SortBy({ List, defaultSelected }) {
    const [active, setActive] = useState(false);
    const [selected, setSelected] = useState(defaultSelected);
    const [selectableList, setSelectableList] = useState(List);
    return (
        <div className='SortByContainer'>
            <div className={`SortBy ${active && 'activeSort'}`} onClick={() => setActive(!active)}>
                <SvgIcon icon='arrowUpDown' size="30" />
                <h4>{selected.label}</h4>
                <SvgIcon icon='caret-down' size="25" />
            </div>

            {
                active &&
                <div className='SortByAll'>
                    {
                        selectableList && selectableList.map((itm) => {
                            if(itm.id !== selected.id) {
                                return <div className='SortByOption' onClick={() => {
                                    setActive(false);
                                    setSelected(itm)
                                }}>
                                        <div />
                                        <h4>{itm.label}</h4>
                                        <div />
                                    </div>

                            }
                        })
                    }

                </div>
            }

        </div>
    )
}

export default SortBy