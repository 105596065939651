import { useState, useEffect } from "react";

export default function useStateForLogin(err = {}, val = {}) {
    const [errors, setErrors] = useState(err)
    const [values, setValues] = useState(val);

    const UpdateErrors = (key, value) => {
        const newErrors = JSON.parse(JSON.stringify(errors));
        newErrors[key] = value;
        setErrors(newErrors);
    }

    const UpdateValues = (key, value) => {
        const newValues = JSON.parse(JSON.stringify(values));
        newValues[key] = value;
        setValues(newValues);
    }

    return { values, UpdateValues, errors, UpdateErrors }
}