import React, { useState } from 'react'
import QCards from '../Comps/QCards'
import './DiscussDetails.css'
import SortBy from '../Comps/SortBy'
import DiscussCard from '../Comps/DiscussCard'
import DiscussCommentCard from '../Comps/DiscussCommentCard'
import SvgIcon from '../../svg/SvgIcon'
import { Button } from 'react-bootstrap'

function DiscussDetails() {
    const [showDiscussQuestions, setShowDiscussionQuestions] = useState(false);
    return (
        <div className='mt-1 DiscussDetailsView'>
            <QCards MyAnswerCard />

            <div className='NoComments'>
                <img src='/assets/img/svg_placeholder/no-comment.svg' />
            </div>

            <SortBy
                defaultSelected={{
                    "id": "1",
                    "label": "Sort by Popularity"
                }}
                List={[
                    {
                        "id": "1",
                        "label": "Sort by Popularity"
                    },
                    {
                        "id": "2",
                        "label": "Sort by Relevance"
                    },
                    {
                        "id": "3",
                        "label": "Sort by Date"
                    },
                    {
                        "id": "4",
                        "label": "Sort by Likes"
                    },
                    {
                        "id": "5",
                        "label": "Sort by Comments"
                    }
                ]}
            />

            <div className='Comments'>
                <DiscussCard />
                <DiscussCommentCard />
                <DiscussCard />
                <DiscussCard />
                <DiscussCard />
            </div>

            <div className="appBottomMenu">
                {
                    showDiscussQuestions &&
                    <div className='AddCommentOrReply'>
                        <div className='AddCommentOrReplyHeader'>
                            <h4 className='PopUpTitle'>Add Comment</h4>
                            <Button variant='link' onClick={() => setShowDiscussionQuestions(false)}>
                                <SvgIcon icon="x" size="20" />
                            </Button>
                        </div>
                        <p>To create a vendor invoice, begin by gathering all necessary information such ...</p>
                    </div>
                }
                <div className='AddCommentInput'>
                    <form>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <input type="text" class="form-control" placeholder="Hop in the conversation" onFocus={() => setShowDiscussionQuestions(true)} onBlur={() => setShowDiscussionQuestions(false)} />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary p-1">
                            <SvgIcon icon="paperPlaneRight" fill='#ffffff' size="20" />
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DiscussDetails