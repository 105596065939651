import React, { useState } from 'react'
import SvgIcon from '../../svg/SvgIcon'
import '../Upskill/QuizAttempt.css'
import { Button, Modal } from 'react-bootstrap'
import QuizQuestionCard from '../Comps/QuizQuestionCard'
import useModalComp from '../../Hooks/useModalComp'
import { useNavigate } from 'react-router'

function QuizAttempt() {
    const navigat = useNavigate()
    const [submitted, setSubmitted] = useState(false);
    const { openModal, closeModal, ModalJSX } = useModalComp();

    const handleOpenConfirmModal = () => {
        openModal("confirm-modal", {
            ModalBodyContent: <div className='ConfirmationQuizModal'>
                <div className='TimeLeft'>
                    <div className='Left'>
                        <SvgIcon icon='timer-duotone' size="24" fill='#9E9E9E' />
                        <p>Time Left</p>
                    </div>
                    <p className='Time'>00:10:10</p>
                </div>

                <div className='dividerLine' />

                <p className='Textline'>Are you Sure Want to End the Quiz?</p>
            </div>,
        });
    };

    const handleOpenSubmitModal = () => {
        openModal("submit-modal", {
            ModalBodyContent: <div className='SubmitQuizModal'>
                <p>Test Submitted Successfully!</p>
                <div className='submitInfo'>
                    <SvgIcon icon='timer-duotone' size="20" />
                    <p>Earned Coins</p>
                    <span className='coins'>10</span>
                </div>

                <div className='dividerLine' />

                <div className='submitInfo'>
                    <SvgIcon icon='checkCircle-duotone' size="20" />
                    <p>Your Score</p>
                    <span className='score'>10/10</span>
                </div>

                <div className='dividerLine' />
            </div>,
        });
    };
    return (
        <div>
            <div className="appHeader mobileMainHeader">
                <div className='QuizeHeader'>
                    <h4>Oracle Cloud SCM Basics </h4>

                    <div className='Timer'>
                        <p>30:10</p>
                        <SvgIcon icon='timer-duotone' size="24" />
                    </div>
                </div>
            </div>

            <div className='QuizContainer container MainPagesSection'>
                <QuizQuestionCard isSubmitted={submitted} />
            </div>


            <div className="appBottomMenu">
                <div className='QuizActions'>
                    <Button variant='link' className='exitButton' onClick={() => navigat('/dashboard')}>Exit</Button>
                    <Button variant='primary' className='submitButton' onClick={() => handleOpenConfirmModal()}>
                        {
                            submitted ? 'Next' : 'Submit'
                        }

                    </Button>
                </div>
            </div>

            {ModalJSX("confirm-modal", {
                showCloseButton: true,
                children: (
                    <div className="ModalAction">
                        <Button variant="link" className='exitButton' onClick={() => closeModal("confirm-modal")}>
                            No
                        </Button>
                        <Button variant="primary" onClick={() => {
                            closeModal("confirm-modal");
                            setSubmitted(true);
                            handleOpenSubmitModal();
                        }}>
                            Yes
                        </Button>
                    </div>
                ),
            })}
            {ModalJSX("submit-modal", {
                showCloseButton: true,
                children: (
                    <div className="ModalAction">
                        <Button variant="primary" onClick={() => {
                            closeModal("submit-modal")
                            }}>
                            Show Answers
                        </Button>
                    </div>
                ),
            })}

        </div>
    )
}

export default QuizAttempt