import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import './Resume.css'
import SvgIcon from '../../svg/SvgIcon'
import { Badge, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { setSubmitStatus } from '../../../features/resumeEdit/resumeSlice'

export const Resume = ({
    submitStatus,
    setSubmitStatus
}) => {
    const navigate = useNavigate()
    useEffect(() => {
        
        setTimeout(() => {
            if(submitStatus === 'waiting') {
                setSubmitStatus('done')
            }
        }, 3000);
        
        // return () => {
        //     if(submitStatus !== null) {
        //         console.log(submitStatus)
        //         setSubmitStatus(null)
        //     }
        // }
    }, [submitStatus])
    
  return (
    <>
        {
            submitStatus ?
            <div className='AltScreens'>
                {
                    submitStatus === 'waiting' ?
                    <div className='SavingScreen'>
                        <div class="loader"></div>
                        <p>Please wait...</p>
                        <span>We are saving your changes</span>
                    </div>
                    :
                    <div className='SavedScreen'>
                        <SvgIcon icon='checkCircle-fill' size="80" fill='#409348' />
                        <p>Thank You</p>
                        <span>Your Details Saved Successfully</span>
                        <Button variant='outline-primary' onClick={() => {
                            setSubmitStatus(null);
                            navigate('/profile')
                            }}>Go to Proffile</Button>
                    </div>
                }

            </div>
            :
            <div className='ResumeView container'>
                <div className='ResumeProfile'>
                    <div className='ProfileImage'>
                        <img src='/assets/img/sample/photo/1.jpg' />
                    </div>
                    <div className='NameButton'>
                        <Button variant='link'>
                            <SvgIcon icon="share-fat-duotone" size="20" />
                        </Button>
                        <h4 className='Name'>Amruta Patil</h4>
                        <div className='Location'>
                            <SvgIcon icon='mappin-doutone' size="16" fill='#757F87' />
                            <span>Mumbai, India</span>
                        </div>
                    </div>
                </div>
                <p className='profileDescription'>Corporate Trainer experience in Oracle EBS and Cloud Consultant</p>
                <div className='profileContactInfo'>
                    
                    <a href='#' target='_blank'>
                        <SvgIcon icon="briefcase-duotone" size="18" fill='#787878' />
                        <span>2 Years of Experience</span>
                    </a>

                    <a href='#' target='_blank'>
                        <SvgIcon icon="phoneCall-duotone" size="18" fill='#787878' />
                        <span>+91 1234567890</span>
                    </a>

                    <a href='#' target='_blank'>
                        <SvgIcon icon="envelopeSimpleOpen-duotone" size="18" fill='#787878' />
                        <span>rajeshjayant@gmail.com</span>
                    </a>

                    <a href='#' target='_blank'>
                        <SvgIcon icon="linkedInLogo-duotone" size="18" fill='#787878' />
                        <span>www.linkedin.com/username</span>
                    </a>

                </div>

                <div className='profileSkillsLanguage'>
                    <h4>Skills</h4>
                    <div className='priorities'>
                        <Badge bg='primary'>Photo Editing</Badge>
                        <Badge bg='primary'>Motion Graphics</Badge>
                        <Badge bg='primary'>3D Design</Badge>
                        <Badge bg='primary'>VFX</Badge>
                        <Badge bg='primary'>3D Animation</Badge>
                    </div>
                </div>

                <div className='profileSkillsLanguage'>
                    <h4>Language</h4>
                    <div className='priorities'>
                        <Badge bg='primary'>English</Badge>
                        <Badge bg='primary'>Hindi</Badge>
                        <Badge bg='primary'>Marathi</Badge>
                    </div>
                </div>

                <div className='ProfileAchiv'>
                    <div className='ProfileAchivTitle'>
                        <h4>Educational Qualifications</h4>
                        <Button variant='link' size='sm'>View All</Button>
                    </div>

                    <div className='Achiv'>
                        <div className='AchivImg'>
                            <img src="/assets/img/hat.png" alt="" />
                        </div>
                        <div className='AchivInfo'>
                            <h5 className='AchivTitle'>Charted Accountant</h5>
                            <h5>ICAI</h5>
                            <p className='iconData'>
                                <SvgIcon icon='calendarBlank-duotone' fill='#757F87' size="16" />
                                <span>April 2021 - Januray 2023</span>
                            </p>
                            <p className='iconData'>
                                <SvgIcon icon='exam-duotone' fill='#757F87' size="16" />
                                <span>58%</span>
                            </p>
                        </div>
                        <p className='AchiDesc'>Implemented most Crucial project for user base of more than 3.4 Cr</p>
                    </div>

                    <div className='Achiv'>
                        <div className='AchivImg'>
                            <img src="/assets/img/hat.png" alt="" />
                        </div>
                        <div className='AchivInfo'>
                            <h5 className='AchivTitle'>Charted Accountant</h5>
                            <h5>ICAI</h5>
                            <p className='iconData'>
                                <SvgIcon icon='calendarBlank-duotone' fill='#757F87' size="16" />
                                <span>April 2021 - Januray 2023</span>
                            </p>
                            <p className='iconData'>
                                <SvgIcon icon='exam-duotone' fill='#757F87' size="16" />
                                <span>58%</span>
                            </p>
                        </div>
                        <p className='AchiDesc'>Implemented most Crucial project for user base of more than 3.4 Cr</p>
                    </div>
                </div>

                <div className='ProfileAchiv'>
                    <div className='ProfileAchivTitle'>
                        <h4>Certifications</h4>
                        <Button variant='link' size='sm'>View All</Button>
                    </div>

                    <div className='Achiv'>
                        <div className='AchivImg'>
                            <img src="/assets/img/certificate.png" alt="" />
                        </div>
                        <div className='AchivInfo'>
                            <h5 className='AchivTitle'>Oracle Cloud Financials</h5>
                            <h5>YourERPCoach.com</h5>
                            <p className='iconData'>
                                <SvgIcon icon='calendarBlank-duotone' fill='#757F87' size="16" />
                                <span>April 2021 - Januray 2023</span>
                            </p>
                            <p className='iconData'>
                                <SvgIcon icon='mappin-duotone' fill='#757F87' size="16" />
                                <span>Credentials</span>
                            </p>
                        </div>
                        <p className='AchiDesc'>Implemented most Crucial project for user base of more than 3.4 Cr</p>
                    </div>

                    <div className='Achiv'>
                        <div className='AchivImg'>
                            <img src="/assets/img/certificate.png" alt="" />
                        </div>
                        <div className='AchivInfo'>
                            <h5 className='AchivTitle'>Oracle Cloud Financials</h5>
                            <h5>YourERPCoach.com</h5>
                            <p className='iconData'>
                                <SvgIcon icon='calendarBlank-duotone' fill='#757F87' size="16" />
                                <span>April 2021 - Januray 2023</span>
                            </p>
                            <p className='iconData'>
                                <SvgIcon icon='sealCheck-duotone' fill='#757F87' size="16" />
                                <span>Credentials</span>
                            </p>
                        </div>
                        <p className='AchiDesc'>Implemented most Crucial project for user base of more than 3.4 Cr</p>
                    </div>
                </div>

                <div className='ProfileAchiv'>
                    <div className='ProfileAchivTitle'>
                        <h4>Job Experiences</h4>
                        <Button variant='link' size='sm'>View All</Button>
                    </div>

                    <div className='Achiv'>
                        <div className='AchivImg'>
                            <img src="/assets/img/job.png" alt="" />
                        </div>
                        <div className='AchivInfo'>
                            <h5 className='AchivTitle'>Associate Consultant</h5>
                            <h5>Foodaspas</h5>
                            <p className='iconData'>
                                <SvgIcon icon='calendarBlank-duotone' fill='#757F87' size="16" />
                                <span>April 2021 - Januray 2023</span>
                            </p>
                            <p className='iconData'>
                                <SvgIcon icon='sealCheck-duotone' fill='#757F87' size="16" />
                                <span>Mumbai, India (Onsite)</span>
                            </p>
                        </div>
                        <p className='AchiDesc'>Implemented most Crucial project for user base of more than 3.4 Cr</p>
                    </div>

                    <div className='Achiv'>
                        <div className='AchivImg'>
                            <img src="/assets/img/job.png" alt="" />
                        </div>
                        <div className='AchivInfo'>
                            <h5 className='AchivTitle'>Associate Consultant</h5>
                            <h5>Foodaspas</h5>
                            <p className='iconData'>
                                <SvgIcon icon='calendarBlank-duotone' fill='#757F87' size="16" />
                                <span>April 2021 - Januray 2023</span>
                            </p>
                            <p className='iconData'>
                                <SvgIcon icon='mappin-duotone' fill='#757F87' size="16" />
                                <span>Mumbai, India (Onsite)</span>
                            </p>
                        </div>
                        <p className='AchiDesc'>Implemented most Crucial project for user base of more than 3.4 Cr</p>
                    </div>
                </div>

                <div className='ProfileAchiv'>
                    <div className='ProfileAchivTitle'>
                        <h4>Job Experiences</h4>
                        <Button variant='link' size='sm'>View All</Button>
                    </div>

                    <div className='Achiv'>
                        <div className='AchivImg'>
                            <img src="/assets/img/project.png" alt="" />
                        </div>
                        <div className='AchivInfo'>
                            <h5 className='AchivTitle'>Oracle Cloud Financials</h5>
                            <h5>Foodaspas</h5>
                            <p className='iconData'>
                                <SvgIcon icon='calendarBlank-duotone' fill='#757F87' size="16" />
                                <span>April 2021 - Januray 2023</span>
                            </p>
                            <p className='iconData'>
                                <SvgIcon icon='sealCheck-duotone' fill='#757F87' size="16" />
                                <span>Credentials</span>
                            </p>
                        </div>
                        <p className='AchiDesc'>Implemented most Crucial project for user base of more than 3.4 Cr</p>
                    </div>

                    <div className='Achiv'>
                    </div>
                </div>
            </div>
        }
    </>
  )
}

const mapStateToProps = ({ resumeData }) => {
    const {submitStatus} = resumeData
    return {
        submitStatus
    }
}


const mapDispatchToProps = {
    setSubmitStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(Resume)