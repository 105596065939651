import React from 'react'
import { connect } from 'react-redux'
import WorkCard from '../Comps/WorkCard'

export const WorkDetails = ({
    jobID
}) => {
  return (
    <div className='WorkDetailsPage pt-1'>
        <WorkCard detailsPage />
    </div>
  )
}

const mapStateToProps = ({ workData }) => {
    const { jobID } = workData;
    return {
        jobID
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(WorkDetails)