import React from 'react'
import '../Comps/Badges.css'
import SvgIcon from '../../svg/SvgIcon'

function Badges({ text, variant = 'badge-time', icon, iconPosition = 'start' }) {
  // badge-time, badge-work, badge-level, badge-coins
  return (
    <span className={`${variant} badgeStyle ${icon && 'icon'}`}>
      {
        icon && iconPosition === 'start' && <SvgIcon className='me-1' icon={icon} size='18' fill='#1589ED' />
      }
      {text}

      {
        icon && iconPosition === 'end' && <SvgIcon className='ms-1' icon={icon} size='18' fill='#1589ED' />
      }
    </span>
  )
}

export default Badges