import React from 'react'
import { Button } from 'react-bootstrap'
import '../Comps/Referral.css';
import useModalComp from '../../Hooks/useModalComp';

function Referral() {

  const { openModal, closeModal, ModalJSX } = useModalComp();

  const handleOpenConfirmModal = () => {
      openModal("Referral-modal", {
          ModalBodyContent: <div className='ReferralModal'>
            <img src="/assets/img/svg_placeholder/share.svg" />
            <h5>Refer your friend now.</h5>
            <p>Your referral code is XXXXXX</p>
          </div>,
      });
  };
  return (
    <div className='ReferralCard'>
        <img src="/assets/img/svg_placeholder/referral.png" alt='' />
        <div className='action'>
            <p>Refer your friends and earn coins</p>
            <Button variant="primary" onClick={handleOpenConfirmModal}>Refer Now</Button>
        </div>

        
      {ModalJSX("Referral-modal", {
                showCloseButton: true,
                enableBackDrop: false,
                children: (
                    <div className="ModalAction">
                        <Button variant="link" className='exitButton' onClick={() => closeModal("Referral-modal")}>
                            Copy Code
                        </Button>
                        <Button variant="primary" onClick={() => {
                            closeModal("Referral-modal");
                        }}>
                            Share
                        </Button>
                    </div>
                ),
            })}
    </div>
  )
}

export default Referral