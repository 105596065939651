import React from 'react'
import './QuizQuestionCard.css'

function QuizQuestionCard({ isSubmitted }) {
  return (
    <>
        <div className={`QuizQuestionCard ${isSubmitted ? 'submitted' : ""}`}>
                <p className='QuestionTitle'>
                    Which of the following in not a correct objective that must be considered when designing the chart of accounts?
                </p>

                <div className='QuestionOptions'>
                    <div className="form-check mb-1">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" disabled={isSubmitted} id="flexRadioDefault1" />
                        <label className="form-check-label" for="flexRadioDefault1">
                            Anticipate growth and maintenance needs as organizational changes occur
                        </label>
                    </div>
                    <div className="form-check mb-1 correct">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" disabled={isSubmitted} id="flexRadioDefault2" />
                        <label className="form-check-label" for="flexRadioDefault2">
                            Effectively manage an organization's financial business
                        </label>
                    </div>

                    <div className="form-check mb-1 wrong">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" disabled={isSubmitted} id="flexRadioDefault3" />
                        <label className="form-check-label" for="flexRadioDefault3">
                            Effectively manage an organization's financial business
                        </label>
                    </div>

                    <div className="form-check mb-1">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" disabled={isSubmitted} id="flexRadioDefault4" />
                        <label className="form-check-label" for="flexRadioDefault4">
                            Effectively manage an organization's financial business
                        </label>
                    </div>
                </div>
                {
                    isSubmitted &&
                    <div className='QuestionExplanation'>
                        <h5>Explation</h5>
                        <p>Which of the following in not a correct objective that must be considered when designing the chart of accounts?</p>
                    </div>
                }

            </div>
    </>
  )
}

export default QuizQuestionCard