import React from 'react'
import Badges from './Badges'
import SvgIcon from '../../svg/SvgIcon'
import ProductModules from './ProductModules'
import '../Comps/ContentCard.css'

function ContentCard() {
  return (
    <div className='ContentCard container'>
        <div className='d-flex gap-2'>
            <Badges variant='badge-coins' text='10 Coins' />
            <Badges variant='badge-time' text='Beginners' />
            <Badges variant='badge-time' text='07:22 min' />
            <Badges variant='badge-time' text='English' />
        </div>
        <div className='ImageContainer'>
            <img src='/assets/img/content.png' className='w-100' />
        </div>
        <div className='ContentDetails'>
            <h5>
                <SvgIcon icon='youtube-fill' size="15" fill='#A9A1A1' />
                <span>YourERPCoach</span>
            </h5>

            <h4>Create Journal Entry in Oracle Cloud ERP</h4>

            <ProductModules />
        </div>
    </div>
  )
}

export default ContentCard