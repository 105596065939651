import React from 'react'

function UserInfoTile() {
  return (
    <div className='UserInfoTile d-flex gap-1 align-items-center'>
        <img src='/assets/img/sample/avatar/avatar1.jpg' alt="user" style={{'height': '30px', 'height': '30px', 'borderRadius' : '50%'}} />
        <span className='text-truncate' style={{
            'fontSize' : '12px', 'maxWidth' : '140px'
        }}>Prashant Nayak</span>
    </div>
  )
}

export default UserInfoTile