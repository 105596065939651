import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout, setActiveUpSkillTab, setActiveDiscuss } from '../../../features/auth/authSlice';
import { setJobID } from '../../../features/job/WorkSlice';
import SvgIcon from '../../svg/SvgIcon'
import { Button } from 'react-bootstrap'
import { setActiveResumeEdit } from '../../../features/resumeEdit/resumeSlice';
import { resumeLinks } from '../../../config'

const Header = ({
  logout,
  Tabs = null,
  activeUpSkillTab,
  setActiveUpSkillTab,
  activeDiscuss,
  setActiveDiscuss,
  jobID,
  setJobID,
  header,
  setActiveResumeEdit,
  activeResumeEdit
}) => {
  let navigate = useNavigate()
  const resumeL = resumeLinks;
  // console.log(activeDiscuss);

  const handleUserProfile = () => {
    navigate('/userProfile')
  }

  function ChangeResume(id) {
    // console.log(resumeL, resumeLinks, id);
    setActiveResumeEdit(id);
    navigate(`/${resumeL[id]}`)
  }
  return (
    <>
      <div className={`appHeader flex-column justify-space-between mobileMainHeader ${Tabs && 'withTabs'}`}>
        <div className="w-100 d-flex align-items-center justify-content-between mobileMainHeader">

          {
            header === 'jobdetails' && <>
              <Button variant='link' onClick={() => {
                setJobID(null);
                navigate('/work');
              }}>
                <SvgIcon icon='arrowLeft-duotone' size="24" />
                <h4 className='mb-0 ms-1 text-dark' style={{'fontWeight': 'bold'}}>
                  Job Details
                </h4>
              </Button>

              <Button variant='link'>
                <SvgIcon icon='dotsThreeOutline-duotone' size="24" />
              </Button>
            </>
          }
          {
            header === 'discussdetails' && <>
            <Button variant='link' onClick={() => {
              navigate('/discuss');
            }}>
              <SvgIcon icon='arrowLeft-duotone' size="24" />
              <h4 className='mb-0 ms-1 text-dark' style={{'fontWeight': 'bold'}}>
                Discussion
              </h4>
            </Button>

            <Button variant='link'>
              <SvgIcon icon='dotsThreeOutline-duotone' size="24" />
            </Button>
          </>
          }
          {
            header === 'coins' && <>
            <Button variant='link' onClick={() => {
              navigate('/dashboard');
            }}>
              <SvgIcon icon='arrowLeft-duotone' size="24" />
              <h4 className='mb-0 ms-1 text-dark' style={{'fontWeight': 'bold'}}>
                Coins
              </h4>
            </Button>

            <Button variant='link'>
              <SvgIcon icon='dotsThreeOutline-duotone' size="24" />
            </Button>
          </>
          }
          {
            header === 'resumeEdit' &&
            <div className='ResumeSteps'>
              <div className={`Step ${activeResumeEdit === '1' ? 'active' : ''}`} onClick={() => ChangeResume('1')}>
                <span className='StepNumber'>1</span>
                <p>Personal</p>
              </div>
              <div className={`Step ${activeResumeEdit === '2' ? 'active' : ''}`} onClick={() => ChangeResume('2')}>
                <span className='StepNumber'>2</span>
                <p>Projects</p>
              </div>
              <div className={`Step ${activeResumeEdit === '3' ? 'active' : ''}`} onClick={() => ChangeResume('3')}>
                <span className='StepNumber'>3</span>
                <p>Certificates</p>
              </div>
              <div className={`Step ${activeResumeEdit === '4' ? 'active' : ''}`} onClick={() => ChangeResume('4')}>
                <span className='StepNumber'>4</span>
                <p>Jobs</p>
              </div>
              <div className={`Step ${activeResumeEdit === '5' ? 'active' : ''}`} onClick={() => ChangeResume('5')}>
                <span className='StepNumber'>5</span>
                <p>Degrees</p>
              </div>
            </div>
          }
          {
            header === 'resume' && <>
            <Button variant='link' onClick={() => {
              navigate('/dashboard');
            }}>
              <SvgIcon icon='arrowLeft-duotone' size="24" />
              <h4 className='mb-0 ms-1 text-dark' style={{'fontWeight': 'bold'}}>
                Resume
              </h4>
            </Button>

            <Button variant='link'>
              <SvgIcon icon='dotsThreeOutline-duotone' size="24" />
            </Button>
          </>
          }
          {
            !header && <>
              <Button variant='link'>
                <SvgIcon icon='hamburgerMenu' size="24" />
              </Button>

              <div>
                  <Button variant='link' className='coinsbutton' onClick={() => navigate('/coins')}>
                    <span style={{'color' : "#AC6A04"}}>100</span> <SvgIcon className='ms-1' icon="coins2" size="25" fill='#AC6A04' />
                  </Button>

                  <Button variant='link' className='notificationButton'>
                    <SvgIcon icon='bell-duotone' size="24" fill='#7B7B7B' />
                  </Button>
              </div>
            </>
          }
        </div>

        {
          Tabs && <div className='HeaderTabs'>
            <div className='d-flex'>
              {
                Tabs === 'Upskill' && <>
                  <Button variant='link' className={`${activeUpSkillTab === 'Quiz' ? 'activeTab' : ''}`} onClick={() => setActiveUpSkillTab('Quiz')}>Quiz</Button>
                  <Button variant='link' className={`${activeUpSkillTab === 'Content' ? 'activeTab' : ''}`} onClick={() => setActiveUpSkillTab('Content')}>Content</Button>
                  <Button variant='link' className={`${activeUpSkillTab === 'Events' ? 'activeTab' : ''}`} onClick={() => setActiveUpSkillTab('Events')}>Events</Button>
                </>
              }

              {
                Tabs === 'Discuss' && <>
                  <Button variant='link' className={`${activeDiscuss === 'Relevant' ? 'activeTab' : ''}`} onClick={() => setActiveDiscuss('Relevant')}>Relevant</Button>
                  <Button variant='link' className={`${activeDiscuss === 'My Questions' ? 'activeTab' : ''}`} onClick={() => setActiveDiscuss('My Questions')}>My Questions</Button>
                  <Button variant='link' className={`${activeDiscuss === 'My Answers' ? 'activeTab' : ''}`} onClick={() => setActiveDiscuss('My Answers')}>My Answers</Button>
                </>
              }
            </div>
          </div>
        }

      </div>
    </>
  )
}

const mapStateToProps = ({ auth, workData, resumeData }) => {
  const { activeUpSkillTab, activeDiscuss } = auth;
  const { activeResumeEdit } = resumeData;
  const { jobID } = workData;
  return {
    activeUpSkillTab,
    jobID,
    activeDiscuss,
    activeResumeEdit
  }
}

const mapDispatchToProps = {
  logout,
  setActiveUpSkillTab,
  setJobID,
  setActiveDiscuss,
  setActiveResumeEdit
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header)
