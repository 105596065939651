import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import Quiz from './Quiz'
import Content from './Content'
import Events from './Events'

export const Upskill = ({
    activeUpSkillTab
}) => {

    const ref = useRef(null);
    useEffect(() => {
        if(ref.current){
            ref.current.scrollIntoView();
            ref.current.scrollTop = 0;
        }
    }, [activeUpSkillTab])

  return (
    <div className='Upskillpage' ref={ref}>
        {
            activeUpSkillTab === 'Quiz' &&
            <>
                <Quiz />
            </>
        }

        {
            activeUpSkillTab === 'Content' &&
            <div>
                <Content />
            </div>
        }

        {
            activeUpSkillTab === 'Events' &&
            <div>
                <Events />
            </div>
        }
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
    const { activeUpSkillTab } = auth;
    return {
      activeUpSkillTab
    }
  }

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Upskill)