import React from 'react'

function SectionTitle({ text }) {
  return (
    <>
        <h2 className='SectionTitle py-3 mb-0' style={{'fontSize': '16px'}}>
            {text}
        </h2>
    </>
  )
}

export default SectionTitle