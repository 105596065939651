import { configureStore } from '@reduxjs/toolkit'
import appToastReducer from '../features/utilities/appToastSlice'
import authReducer from '../features/auth/authSlice'
import workReducer from '../features/job/WorkSlice'
import resumeReducer from '../features/resumeEdit/resumeSlice'

export const store = configureStore({
  reducer: {
    appToast: appToastReducer,
    auth: authReducer,
    workData: workReducer,
    resumeData: resumeReducer,
  },
});