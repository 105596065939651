import React from 'react'
import SvgIcon from '../../svg/SvgIcon'

function ProductModules() {
    return (
        <>
            <div className='productModules'>
                <span>Oracle Cloud</span>
                <SvgIcon icon="caret-right" fill='#A9A1A1' size="12" />
                <span>Financials</span>
                <SvgIcon icon="caret-right" fill='#A9A1A1' size="12" />
                <span>Accounts Payable</span>
            </div>
        </>
    )
}

export default ProductModules