import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export default function useModalComp() {
  // Array to store multiple modal states
  const [modals, setModals] = useState([]);

  const openModal = (modalId, modalProps) => {
    setModals(prevModals => [...prevModals, { id: modalId, ...modalProps, show: true }]);
  };

  const closeModal = (modalId) => {
    setModals(prevModals => prevModals.filter(modal => modal.id !== modalId));
  };

  return {
    openModal,
    closeModal,
    ModalJSX: (modalId, props) => {
      const modal = modals.find(m => m.id === modalId);

      if (!modal) {
        return null; // Handle missing modal gracefully
      }

      const { showCloseButton = false, enableBackDrop = true, ...rest } = props;

      return (
        <Modal
          show={modal.show}
          backdrop={enableBackDrop === true ? "static" : true}
          keyboard={false}
          centered
          onHide={() => closeModal(modalId)}
        >
          {modal.ModalHeaderContent && (
            <Modal.Header closeButton={showCloseButton}>
              {modal.ModalHeaderContent}
            </Modal.Header>
          )}
          {modal.ModalBodyContent && (
            <Modal.Body>
                {modal.ModalBodyContent}
                {rest.children}
            </Modal.Body>
          )}
        </Modal>
      );
    },
  };
}
