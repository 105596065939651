import React from 'react'
import { connect } from 'react-redux'
import useSearchInputFilterBtn from '../../Hooks/useSearchInputFilterBtn'
import ContentCard from '../Comps/ContentCard'

export const Content = (props) => {
    const { SearchInputFilterBtnContainer } = useSearchInputFilterBtn('Search Content')
  return (
    <>
        <div className='container'>
                <SearchInputFilterBtnContainer />
        </div>
        <div className='d-flex flex-column gap-2'>
            {
                [1,2,3].map((_, i) => {
                    return (
                        <ContentCard key={i} />
                    )
                })
            }
        </div>
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Content)