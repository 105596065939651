import React from 'react';
import '../Comps/TrendingTopicsCard.css';
import UserInfoTile from './UserInfoTile';
import { Button } from 'react-bootstrap';
import SvgIcon from '../../svg/SvgIcon';

function TrendingTopicsCard() {
  return (
    <div className='TrendingTopicsCard'>
        <h5>How to Create invoice?  How to Create invoice?</h5>
        <p>What are the steps to create a vendor invoice, the steps to create a vendor invoice ?</p>
        <div className='TopicInfo'>
            <UserInfoTile />
            <div className='TopicReactions d-flex'>
                <Button variant='link' size='sm'>
                    <SvgIcon icon='like-fill' size="20" fill='#9E9E9E' />
                    <span className='ms-1' style={{'color': '#9E9E9E'}}>99</span>
                </Button>
                <Button variant='link' size='sm'>
                    <SvgIcon icon='chatsCenteredDots-fill' fill='#9E9E9E' size="20" />
                    <span className='ms-1' style={{'color': '#9E9E9E'}}>99</span>
                </Button>
            </div>
        </div>
    </div>
  )
}

export default TrendingTopicsCard