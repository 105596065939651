import React from 'react'
import { connect } from 'react-redux'
import './Coins.css'
import SvgIcon from '../../svg/SvgIcon'

export const Coins = (props) => {
  return (
    <div className='CoinsView container'>
        <div className='Totalcoins'>
            <span style={{'color' : "#AC6A04"}}>100</span> <SvgIcon className='ms-1' icon="coins2" size="80" fill='#AC6A04' />
        </div>
        <p>Coins can be used to apply to jobs and can be earned by contributing in discussions and attempting quizzes.</p>

        <div className='divider' />

        <div className='transactionsHeader'>
            <h4>Transaction</h4>
            <h4>Coins</h4>
        </div>

        <div className='divider' />

        <div className='transactionsEntry'>
            <p>Updated Resume</p>
            <div><span style={{'color' : "#AC6A04"}}>100</span> <SvgIcon className='ms-1' icon="coins2" size="25" fill='#AC6A04' /></div>
        </div>

        <div className='transactionsEntry'>
            <p>Applied Job</p>
            <div><span style={{'color' : "#AC6A04"}}>-10</span> <SvgIcon className='ms-1' icon="coins2" size="25" fill='#AC6A04' /></div>
        </div>

        <div className='transactionsEntry'>
            <p>Updated Resume</p>
            <div><span style={{'color' : "#AC6A04"}}>-10</span> <SvgIcon className='ms-1' icon="coins2" size="25" fill='#AC6A04' /></div>
        </div>

        <div className='transactionsEntry'>
            <p>Applied Job</p>
            <div><span style={{'color' : "#AC6A04"}}>-10</span> <SvgIcon className='ms-1' icon="coins2" size="25" fill='#AC6A04' /></div>
        </div>

        <div className='transactionsEntry'>
            <p>Updated Resume</p>
            <div><span style={{'color' : "#AC6A04"}}>-10</span> <SvgIcon className='ms-1' icon="coins2" size="25" fill='#AC6A04' /></div>
        </div>

        <div className='transactionsEntry'>
            <p>Applied Job</p>
            <div><span style={{'color' : "#AC6A04"}}>-10</span> <SvgIcon className='ms-1' icon="coins2" size="25" fill='#AC6A04' /></div>
        </div>

        <div className='transactionsEntry'>
            <p>Updated Resume</p>
            <div><span style={{'color' : "#AC6A04"}}>-10</span> <SvgIcon className='ms-1' icon="coins2" size="25" fill='#AC6A04' /></div>
        </div>

        <div className='transactionsEntry'>
            <p>Applied Job</p>
            <div><span style={{'color' : "#AC6A04"}}>-10</span> <SvgIcon className='ms-1' icon="coins2" size="25" fill='#AC6A04' /></div>
        </div>

        <div className='transactionsEntry'>
            <p>Updated Resume</p>
            <div><span style={{'color' : "#AC6A04"}}>-10</span> <SvgIcon className='ms-1' icon="coins2" size="25" fill='#AC6A04' /></div>
        </div>

        <div className='transactionsEntry'>
            <p>Applied Job</p>
            <div><span style={{'color' : "#AC6A04"}}>-10</span> <SvgIcon className='ms-1' icon="coins2" size="25" fill='#AC6A04' /></div>
        </div>

        <div className='transactionsEntry'>
            <p>Updated Resume</p>
            <div><span style={{'color' : "#AC6A04"}}>-10</span> <SvgIcon className='ms-1' icon="coins2" size="25" fill='#AC6A04' /></div>
        </div>


        <div className='transactionsEntry'>
            <p>Applied Job</p>
            <div><span style={{'color' : "#AC6A04"}}>-10</span> <SvgIcon className='ms-1' icon="coins2" size="25" fill='#AC6A04' /></div>
        </div>

        <div className='transactionsEntry'>
            <p>Updated Resume</p>
            <div><span style={{'color' : "#AC6A04"}}>-10</span> <SvgIcon className='ms-1' icon="coins2" size="25" fill='#AC6A04' /></div>
        </div>

        <div className='transactionsEntry'>
            <p>Applied Job Applied Job Applied Job Applied Job Applied Job Applied Job Applied Job Applied Job</p>
            <div><span style={{'color' : "#AC6A04"}}>-10</span> <SvgIcon className='ms-1' icon="coins2" size="25" fill='#AC6A04' /></div>
        </div>

        <div className='transactionsEntry'>
            <p>Updated Resume</p>
            <div><span style={{'color' : "#AC6A04"}}>-10</span> <SvgIcon className='ms-1' icon="coins2" size="25" fill='#AC6A04' /></div>
        </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Coins)