import React from 'react'
import { Form, InputGroup, Button, Row, Col } from 'react-bootstrap';
import '../Registration/Registration.css';
import Select from 'react-select';
import { useNavigate } from 'react-router';

function Registration() {
    const navigate = useNavigate();
    const options = [
        { value: 'Product 1', label: 'Product 1' },
        { value: 'Product 2', label: 'Product 2' },
      ]

    return (
        <div className='container appHeight registration'>
            <img src='/assets/img/YeccFull.svg' alt='YourERPCoach' className='Logo' />

            <Form className='registrationForm'>
                <div>
                    <Row>
                        <Form.Group as={Col} xs="6" className="mb-2">
                            <Form.Label>First Name*</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="First name"
                                defaultValue=""
                            />
                        </Form.Group>
                        <Form.Group as={Col} xs="6" className="mb-2">
                            <Form.Label>Last Name*</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Last name"
                                defaultValue=""
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="12">
                            <Form.Label>Email ID*</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="abc@example.com"
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="12">
                            <Form.Label>Mobile Number*</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter Mobile Number"
                                defaultValue=""
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="12">
                            <Form.Label>Product</Form.Label>
                            <Select isClearable placeholder="Select Product" options={options} />
                        </Form.Group>
                    </Row>

                    <Row className="mb-2">
                        <Form.Group as={Col} md="12">
                            <Form.Label>Track</Form.Label>
                            <Select isClearable placeholder="Select Track" options={options} />
                        </Form.Group>
                    </Row>

                    <Row className="mb-2">
                        <Form.Group as={Col} md="12">
                            <Form.Label>Modules</Form.Label>
                            <Select
                                isClearable
                                isMulti
                                className='multipleSelected'
                                placeholder="Select Modules"
                                options={options} />
                        </Form.Group>
                    </Row>

                    <Row className="mb-2">
                    <Form.Group as={Col} xs="12">
                        <Form.Label>Referral Code</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="xxxxx"
                            defaultValue=""
                        />
                    </Form.Group>
                </Row>
                </div>
                <Button type="button" onClick={() => navigate('/dashboard')}>Continue</Button>
            </Form>

        </div>
    )
}

export default Registration