import React from 'react'
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';

function GenerateResumeCard() {
    const navigate = useNavigate();
    const percentage = 66;
    return (
        <>
            <div className='ResumeBuildCard'>
                <div className='ResumePercantage'>
                    <CircularProgressbarWithChildren value={percentage} styles={buildStyles({
                        pathColor: "#1589ED",
                        trailColor: "#D0E7FB"
                    })}>
                        <img
                            style={{ width: 60, marginTop: -5 }}
                            src="/assets/img/svg_placeholder/resume-svgrepo-com.svg"
                            alt=""
                        />
                    </CircularProgressbarWithChildren>
                </div>
                <div className='ResumeData'>
                    <p>Build and share your interactive resume to standout from the crowd of job seekers.</p>
                    <Button variant='primary' onClick={() => navigate('/resume')}>
                        Start Building
                    </Button>
                </div>
            </div>
        </>
    )
}

export default GenerateResumeCard