import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from "react-redux"
import SvgIcon from '../../svg/SvgIcon';
import { Button } from 'react-bootstrap';
import { setActiveResumeEdit } from '../../../features/resumeEdit/resumeSlice';
import { resumeLinks } from '../../../config'

const Footer = ({ footer, activeResumeEdit, setActiveResumeEdit, submitStatus }) => {
    const navigate = useNavigate()

    const pathname = window.location.pathname;

    const resumeL = resumeLinks;

    function ChangeResume(id) {
        console.log(resumeL, resumeLinks, id);
        if(id === '1') {
            setActiveResumeEdit("2");
            navigate(`/${resumeL["2"]}`)
        }
        if(id === '2') {
            setActiveResumeEdit("3");
            navigate(`/${resumeL["3"]}`)
        }
        if(id === '3') {
            setActiveResumeEdit("4");
            navigate(`/${resumeL["4"]}`)
        }
        if(id === '4') {
            setActiveResumeEdit("5");
            navigate(`/${resumeL["5"]}`)
        }
        if(id === '5') {
            setActiveResumeEdit("1");
            navigate(`/profile`)
        }
    }
    return (
        <>
        <div className="section full d-block d-md-none mobileMainFooter">
            {
                footer === 'coins' && <>
                <div className='d-flex gap-2 px-2 py-0 mt-1'>
                    <Button variant='link' className='exitButton w-100' onClick={() => navigate('/work')}>Apply to Jobs</Button>
                    <Button variant='primary' className='w-100' onClick={() => navigate('/upskill')}>Upskill</Button>
                </div>
                </>
            }

            {
                footer === 'resumeEdit' &&
                <div className='d-flex gap-2 px-2 py-0 mt-1'>
                    <Button variant='link' className='exitButton w-100' onClick={() => navigate('/profile')}>Exit</Button>
                    <Button variant='primary' className='w-100' onClick={() => ChangeResume(activeResumeEdit)}>Save {activeResumeEdit}</Button>
                </div>
            }
            {
                !footer && !submitStatus && <>
                    <div className="appBottomMenu">
                        <Link to="/dashboard" className={`item ${pathname === "/dashboard" && 'active'} text-secondary`}>
                            <div className="col d-flex justify-content-center align-items-center flex-column">
                                <SvgIcon icon='home-duotone' size="24" fill={pathname === "/dashboard" ? '#1165EF' : '#787878'} />
                                <strong>Home</strong>
                            </div>
                        </Link>
                        <Link to="/upskill" className={`item ${pathname === "/upskill" && 'active'} text-secondary`}>
                            <div className="col">
                                {/* <ion-icon name="cube-outline" className="fs-4"></ion-icon> */}
                                <SvgIcon icon='bookBookmark-duotone'  fill={pathname === "/upskill" ? '#1165EF' : '#787878'} size="24" />
                                <strong>Upskill</strong>
                            </div>
                        </Link>
                        <Link to="/discuss" className={`item ${pathname === "/discuss" && 'active'} text-secondary`}>
                            <div className="col">
                                <SvgIcon icon='chats-duotone'  fill={pathname === "/discuss" ? '#1165EF' : '#787878'} size="24" />
                                <strong>Discuss</strong>
                            </div>
                        </Link>
                        <Link to="/work" className={`item ${pathname === "/work" && 'active'} text-secondary`}>
                            <div className="col">
                                <SvgIcon icon='briefcase-duotone'  fill={pathname === "/work" ? '#1165EF' : '#787878'} size="24" />
                                <strong>Work</strong>
                            </div>
                        </Link>
                        <Link to="/profile" className={`item ${pathname === "/profile" && 'active'} text-secondary`}>
                            <div className="col">
                                <SvgIcon icon='userCircle-duotone'  fill={pathname === "/profile" ? '#1165EF' : '#787878'} size="24" />
                                <strong>Profile</strong>
                            </div>
                        </Link>
                    </div>
                </>
            }
        </div>
        </>
    );
}

const mapStateToProps = ({ resumeData }) => {
    const { activeResumeEdit, submitStatus } = resumeData;
    return {
        activeResumeEdit,
        submitStatus
    }
  }
  
  const mapDispatchToProps = {
    setActiveResumeEdit,
  }

export default connect(mapStateToProps, mapDispatchToProps)(Footer)