import React, { useState } from 'react'
import '../Login/Login.css'
import EnterMobile from './EnterMobile'

function Login() {

    const [verifyPage, setVerifyPage] = useState(false);
    return (
        <div className='container appHeight authentication'>
            <img src='/assets/img/YeccFull.svg' alt='YourERPCoach' className='Logo' />
            {
                verifyPage ?
                <img src='/assets/img/svg_placeholder/verifyOtp_placeholder.svg' alt='Login' className='LoginPlaceHolderImg' />
                :
                <img src='/assets/img/svg_placeholder/login_placeholder.svg' alt='Login' className='LoginPlaceHolderImg' />
            }
            <div className='LoginContainer position-relative'>
                <h2 className='text-center fs-pgtitle'>
                    {/* { verifyPage ? "Learn" :  */}
                    Network. Learn. Work
                    {/* // } */}
                </h2>
                <EnterMobile isVerifyPageActive={verifyPage} setVerifyPage={setVerifyPage} />
            </div>
            <div className='CopyRight'>
                <p>By continuing, you agree to our</p>
                <div>
                    <a href="https://www.yourerpcoach.com">Terms of Service</a>
                    <a href="https://www.yourerpcoach.com">Privacy Policy</a>
                    <a href="https://www.yourerpcoach.com">Content Policy</a>
                </div>
            </div>
        </div>
    )
}

export default Login