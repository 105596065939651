import { useState } from "react"
import SvgIcon from "../svg/SvgIcon";
import { Button } from "react-bootstrap";

export default function useSearchInputFilterBtn(
    placeholderText = 'Search'
) {
    const [searchText, setSearchText] = useState('');

    const SearchInputJSX = () => {
        return (
            <form class="searchform">
                <input type="text" class="form-control" placeholder={placeholderText} />
                <Button variant="link" className={`${searchText.trim().length === 0 ? 'disable' : ''} px-2`}>
                    <SvgIcon icon="search-duotone" size="20" fill="#f1f1f1" />
                </Button>
            </form>
        )
    }

    const FilterBtn = () => {
        return (
            <Button variant="outline-secondary" className="btn-icon btn-icon-only FilterBtn">
                <SvgIcon icon="sliders" size="20" fill="#000" />
            </Button>
        )
    }

    const SearchInputFilterBtnContainer = () => {
        return (
            <div className="SearchInputFilterBtnContainer">
                <div className="searchContainer">
                    <SearchInputJSX />
                </div>
                <FilterBtn />
            </div>
        )
    }
    return { SearchInputJSX, searchText, setSearchText, FilterBtn, SearchInputFilterBtnContainer }
}