import React from 'react'
import '../Comps/WorkCard.css'
import Badges from './Badges'
import SvgIcon from '../../svg/SvgIcon'
import { Button } from 'react-bootstrap'

function WorkCard({ detailsPage }) {
  return (
    <div className='workcard'>
        <div className='workbadges d-flex justify-content-between'>
            <Badges variant='badge-work' icon="arrowUpRight" iconPosition="start" text='Actively hiring' />
          <Badges variant='badge-coins' text="-10 coins" />
        </div>

        {/* <div className='workdetails'> */}

          <h3 className='workPositionName'>Junior Consultant</h3>
          <div className='workCompanyTime'>

            <div className='company'>
              <img src='/assets/img/sample/photo/1.jpg' alt='company image' />
              <h5>XConnect</h5>
            </div>

            <div className='time'>
              <p>3 Weeks Ago</p>
            </div>

          </div>

          <div className='positionDetails'>
            <div className='detail'>
              <SvgIcon icon='briefcase-duotone' fill='#9E9E9E' size='18' />
              <p>1 year Experience</p>
            </div>

            <div className='detail'>
              <SvgIcon icon='mappin-doutone' fill='#9E9E9E' size='18' />
              <p>Mumbai, India (Onsite)</p>
            </div>

            <div className='detail'>
              <SvgIcon icon='crosshair-duotone' fill='#9E9E9E' size='18' />
              <p>Supply Chain Management</p>
            </div>

            <div className='detail'>
              <SvgIcon icon='money-duotone' fill='#9E9E9E' size='18' />
              <p>5,00,000 - 8,00,000</p>
            </div>

            <div className='detail'>
              <SvgIcon icon='crosshairSimple-duotone' fill='#9E9E9E' size='18' />
              <p>Specialised Assignment, Rollout</p>
            </div>
          </div>

          {/* {
            !detailsPage && */}
            <div className={`JobAction ${detailsPage ? 'detailsButton' : ''}`}>
              <div className='userAction'>
                <Button variant='link'>
                  <SvgIcon icon="bookmark-duotone" size="22" />
                </Button>

                <Button variant='link'>
                  <SvgIcon icon="share-fat-duotone" size="22" />
                </Button>
              </div>
              <div className='ApplyAction'>
                <Button variant='primary'>
                  Apply Now
                </Button>
              </div>
            </div>
          {/* } */}

          {
            detailsPage && <>
            <h3 className='mb-0 mt-1'>About XConnect</h3>
            <p className='AboutCompany mb-0'>
              Lorem Ipsum Lorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem Ipsum
            </p>

            <h3 className='mb-0 mt-1'>Other Details</h3>
            <p className='AboutCompany mb-0'>
              Lorem Ipsum Lorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem Ipsum
            </p>

            <div className='productTrackModule'>
              <div>
                  <h5>Product</h5>
                  <p>Oracle-E-Business Suite</p>
              </div>

              <div>
                  <h5>Track</h5>
                  <p>Project Portfolio Management </p>
              </div>

              <div>
                  <h5>Modules</h5>
                  <p>Project Costing, Project Planning and Contracts, Project Portfolio Analysis</p>
              </div>
            </div>
            </>
          }
        {/* </div> */}


    </div>
  )
}

export default WorkCard