import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  jobID: null,
}

export const workSlice = createSlice({
  name: 'workData',
  initialState,
  reducers: {
    setJobID: (state, action) => {
      state.jobID = action.payload
    },
  }
})

export const { setJobID } = workSlice.actions;


export default workSlice.reducer
