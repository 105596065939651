import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from '../../AxiosConfig'
import { showAppToast } from '../../features/utilities/appToastSlice'

const Load = ({
  categoriesDataUpdate,
  itemsDataUpdate
}) => {
  let navigate = useNavigate()
  return (
    <div id="appCapsule" className="pt-5">
      <div className="error-page" style={{ padding: '0px', maxWidth: 'initial' }}>
        <div className="icon-box text-danger">
          {/* <img
            src={Loading}
            alt="Brand"
            width="100%"
            style={{ maxWidth: '550px' }}
          /> */}
        </div>
        <button className="btn btn-light btn-lg rounded mb-1" disabled>
          <span
            className="spinner-grow spinner-grow-sm me-05"
            role="status"
            aria-hidden="true"
          ></span>
          Loading...
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { authToken } = auth

  return {
    authToken
  }
}

const mapDispatchToProps = {
  showAppToast
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Load)
