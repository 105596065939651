import React from 'react'
import './DiscussCard.css'
import { Button } from 'react-bootstrap'
import SvgIcon from '../../svg/SvgIcon'
import UserInfoTile from './UserInfoTile'

function DiscussCard() {
    return (
        <div className='DiscussCard'>
            <div className='DiscussCardHeader'>
                <UserInfoTile />
                <p className='DiscussAddedTime'>2 hour ago</p>
            </div>
            <p className='DiscussMessage'>Review the invoice for any errors or discrepancies before finalizing and sending it to the vendor for payment processing. Finally, keep records of the invoice for future reference and reconciliation.</p>
            <div className='DiscussionAction'>
                <Button variant='link' size='sm'>
                    <SvgIcon icon='like-duotone' size="20" fill='#000000' />
                    <span className='ms-1' style={{ 'color': '#000000' }}>99</span>
                </Button>
                <Button variant='link' size='sm'>
                    <SvgIcon icon='chatsCenteredDots-duotone' fill='#000000' size="20" />
                    <span className='ms-1' style={{ 'color': '#000000' }}>99</span>
                </Button>
                <Button variant='link' size='sm'>
                    <SvgIcon icon='dotsThreeOutlineHorizontal-duotone' size="20" fill='#000000' />
                </Button>
            </div>
        </div>
    )
}

export default DiscussCard