import React from 'react'
import './QCards.css'
import UserInfoTile from './UserInfoTile'
import Badges from './Badges'
import { Button } from 'react-bootstrap'
import SvgIcon from '../../svg/SvgIcon'
import { useNavigate } from 'react-router'

function QCards({ RelevantCard, MyQuestionsCard, MyAnswerCard }) {
    const navigat = useNavigate();
  return (
    <div className='ReleventQCard container' onClick={() => navigat('/discussDetails/1')}>

        {
            (RelevantCard || MyQuestionsCard) &&
            <>
                <div className='headerContianer'>
                    <div className='headerCard'>
                        <UserInfoTile />
                        <p className='data'>10 - Dec - 2023</p>
                    </div>
                    <h4 className='questiontitle'>How to Create invoice?  How to Create invoice? </h4>
                    <p className='questionDescription'>
                        What are the steps to create a vendor invoice ? What are the steps to create a vendor invoice ?
                    </p>
                </div>
                <Badges variant='badge-level' text='Account Payable' />
            </>
        }

        {
            MyAnswerCard &&
            <div className='headerContianer'>
                <div className='headerCard'>
                    <UserInfoTile />
                    <p className='data'>10 - Dec - 2023</p>
                </div>
                <h4 className='questiontitle'>How to Create invoice?  How to Create invoice? </h4>
                <div className='questionbadges'>
                    <Badges variant='badge-level' text='Account Payable' />
                    <SvgIcon icon="caret-right" fill='#A9A1A1' size="12" />
                    <Badges variant='badge-level' text='Account Payable' />
                    <SvgIcon icon="caret-right" fill='#A9A1A1' size="12" />
                    <Badges variant='badge-level' text='Account Payable' />
                </div>
                <p className='questionDescription'>
                To create a vendor invoice, begin by gathering all necessary information such as vendor details and invoice amounts. Next, input this information into your accounting software or template, ensuring accuracy and completeness. Then, review the invoice for any errors or discrepancies before finalizing and sending it to the vendor for payment processing. Finally, keep records of the invoice for future reference and reconciliation.
                </p>
            </div>
        }

        {
            RelevantCard &&
            <>
                <div className='cardActions'>
                    <div className='TopCommentText'>
                        This is most stupid questi..
                    </div>
                    <div className='actions'>
                        <Button variant='link' size='sm'>
                            <SvgIcon icon='chatsCenteredDots-duotone' fill='#000000' size="20" />
                            <span className='ms-1' style={{'color': '#000000'}}>99</span>
                        </Button>
                        <Button variant='link' size='sm'>
                            <SvgIcon icon='like-duotone' size="20" fill='#000000' />
                            <span className='ms-1' style={{'color': '#000000'}}>99</span>
                        </Button>
                    </div>
                </div>
            </>
        }

        {
            (MyQuestionsCard || MyAnswerCard) &&
            <>
                <div className='QActions'>
                    <Button variant='link' size='sm'>
                        <SvgIcon icon='like-duotone' size="20" fill='#000000' />
                        <span className='ms-1' style={{'color': '#000000'}}>99</span>
                    </Button>
                    <Button variant='link' size='sm'>
                        <SvgIcon icon='chatsCenteredDots-duotone' fill='#000000' size="20" />
                        <span className='ms-1' style={{'color': '#000000'}}>99</span>
                    </Button>
                    <Button variant='link' size='sm'>
                        <SvgIcon icon='bookmark-duotone' size="20" fill='#000000' />
                    </Button>
                    <Button variant='link' size='sm'>
                        <SvgIcon icon='share-fat-duotone' size="20" fill='#000000' />
                    </Button>
                </div>
            </>
        }
    </div>
  )
}

export default QCards