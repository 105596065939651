import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import QuizCard from '../Comps/QuizCard'
import useSearchInputFilterBtn from '../../Hooks/useSearchInputFilterBtn'

export const Quiz = (props) => {
    const { SearchInputFilterBtnContainer } = useSearchInputFilterBtn('Search Test');
    
  return (
    <div className='container'>  
        <div className='mb-2'>
            <SearchInputFilterBtnContainer />
        </div>
        <div className='d-flex flex-column gap-2'>
            {
                [1,2,3,4,5].map((_, i) => {
                    return <QuizCard />
                })
            }
            {
                [1,2,3,4].map((_, i) => {
                    return <QuizCard data={{'status': 'disabled'}} />
                })
            }
        </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Quiz)