
export const Messages = {
    'EmailMobileRequired' : 'Email / Mobile Required',
    'EmailRequired': 'Email is required',
    'ValidEmail': 'Please enter a valid email',
    'ValidMobile': 'Please enter a valid mobile number',
    'OTPRequired': 'OTP is required',
    'OTPLength': 'OTP length must be 6',
}


export const resumeLinks = {
    "1" : "editResumePersonal",
    "2" : "editResumeProjects",
    "3" : "editResumeCertificates",
    "4" : "editResumeJobs",
    "5" : "editResumeDegrees"
}

const config = {
    appUrl : "http://localhost:3000",
    // apiUrl : "https://api.webweb.ai"
    apiUrl : "https://api.allpos.in"
}
  
export default config