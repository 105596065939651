import React from 'react'
import { connect } from 'react-redux'
import useSearchInputFilterBtn from '../../Hooks/useSearchInputFilterBtn';
import WorkCard from '../Comps/WorkCard';
import { Link, useNavigate } from 'react-router-dom';
import { setJobID } from '../../../features/job/WorkSlice';

export const Work = ({
    jobID,
    setJobID
}) => {
    const navigate = useNavigate()
    const { SearchInputFilterBtnContainer } = useSearchInputFilterBtn('Search work');
    function GoToDetails(id) {
        setJobID(id);
        navigate(`/workDetails/${id}`);
    }
  return (
    <div className='Workpage'>
        <div className='container'>
            <div className='mb-0'>
                <SearchInputFilterBtnContainer />
            </div>
        </div>

        <div className='d-flex flex-column gap-2'>
            {
                [1,2,3].map((_, i) => {
                    return <div key={i} onClick={()=> GoToDetails(i)}>
                        <WorkCard />
                    </div>
                })
            }
        </div>
    </div>
  )
}

const mapStateToProps = ({ auth, workData }) => {
    const {  } = auth;
    const { jobID } = workData;
    return {
        jobID
    }
  }

const mapDispatchToProps = {
    setJobID
}

export default connect(mapStateToProps, mapDispatchToProps)(Work)