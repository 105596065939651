import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import './Discuss.css'
import Relevant from './Relevant';
import MyQuestions from './MyQuestions';
import MyAnswers from './MyAnswers';

export const Discuss = ({
  activeDiscuss
}) => {

  const ref = useRef(null);
  useEffect(() => {
      if(ref.current){
          ref.current.scrollIntoView();
          ref.current.scrollTop = 0;
      }
  }, [activeDiscuss]);
  
  return (
    <div className='DiscussView' ref={ref}>
      {
            activeDiscuss === 'Relevant' &&
            <>
              <Relevant />
            </>
        }

        {
            activeDiscuss === 'My Questions' &&
            <div>
              <MyQuestions />
            </div>
        }

        {
            activeDiscuss === 'My Answers' &&
            <div>
              <MyAnswers />
            </div>
        }
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { activeDiscuss } = auth;
    return {
      activeDiscuss
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Discuss)