import React from 'react'
import SvgIcon from '../../svg/SvgIcon'
import { Button } from 'react-bootstrap'
import '../Comps/EventsCard.css';
import Badges from './Badges';

function EventsCard() {
  return (
    <div className='EventsCard'>
        <div className='data-badges'>
            <Badges variant='badge-coins' text="10 Coins" />

            <Badges variant='badge-level' text="Beginners" />

            <Badges variant='badge-time' text="English" />

            <Badges variant='badge-time' text="24-June-24 | 05PM" />
        </div>
        <div className='data-image'>
            <img src='/assets/img/sample/photo/1.jpg' alt='' />
        </div>
        <h4>Oracle Cloud SCM Basics for material procurement and much more</h4>
        <div className='data-buttons'>
            <Button variant='outline-primary'>Share</Button>
            <Button variant='primary'>Registry Now</Button>

        </div>
    </div>
  )
}

export default EventsCard