import React from 'react'
import { connect } from 'react-redux'
import GenerateResumeCard from '../Comps/GenerateResumeCard';
import SectionTitle from '../Comps/SectionTitle';
import TrendingTopicsCard from '../Comps/TrendingTopicsCard';
import QuizCard from '../Comps/QuizCard';
import Referral from '../Comps/Referral';
import { Button } from 'react-bootstrap';

export const Dashboard = (props) => {

  return (
    <div className='DashboradView container'>
      <GenerateResumeCard />
      <SectionTitle text="Top Discussions" />
      <div className='d-flex flex-column gap-3'>
        <TrendingTopicsCard />
        <TrendingTopicsCard />
      </div>
      <SectionTitle text="Quiz of the Day" />
      <QuizCard />
      <SectionTitle text="Referral" />
      <Referral />

    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)