import React from 'react'
import { connect } from 'react-redux'
import useSearchInputFilterBtn from '../../Hooks/useSearchInputFilterBtn'
import EventsCard from '../Comps/EventsCard'

export const Events = (props) => {
    const { SearchInputFilterBtnContainer } = useSearchInputFilterBtn('Search Events')
  return (
    <>
        <div className='container'>
            <div className='mb-0'>
                <SearchInputFilterBtnContainer />
            </div>
        </div>
        <div className='d-flex flex-column gap-2'>
          {
              [1,2,3].map((_, i) => {
                  return <EventsCard key={i} />
              })
          }
        </div>
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Events)