import React, { useState } from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputGroup from 'react-bootstrap/InputGroup';
import { connect } from 'react-redux';
import { setActiveResumeEdit, setSubmitStatus } from '../../../features/resumeEdit/resumeSlice';
import * as formik from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import './EditResume.css';
import useModalComp from '../../Hooks/useModalComp'
import { useNavigate } from 'react-router';

export const Personal = ({
  activeResumeEdit,
  setActiveResumeEdit,
  setSubmitStatus
}) => {

  const navigat = useNavigate();

  const { openModal, closeModal, ModalJSX } = useModalComp();
  
  const handleOpenConfirmModal = () => {
    openModal("confirm-modal", {
        ModalBodyContent: <div className='ConfirmationQuizModal'>
            <span className='mb-0 text-center'>
              The changes are not saved
              <br />
              <b>Are you Sure Want to Cancel?</b>
            </span>
        </div>,
    });
  };

  const [CountryOption, setCountryOption] = useState(null);
  const CountryOptions = [
    { value: 'One', label: 'One' },
    { value: 'Two', label: 'Two' },
    { value: 'Three', label: 'Three' },
  ];

  const [CountryCodeOption, setCountryCodeOption] = useState(null);
  const CountryCodeOptions = [
    { value: '+91', label: '+91' },
    { value: '+92', label: '+92' },
    { value: '+93', label: '+93' },
  ];

  const [StateOption, setStateOption] = useState(null);
  const StateOptions = [
    { value: 'One', label: 'One' },
    { value: 'Two', label: 'Two' },
    { value: 'Three', label: 'Three' },
  ];

  const [CityOption, setCityOption] = useState(null);
  const CityOptions = [
    { value: 'One', label: 'One' },
    { value: 'Two', label: 'Two' },
    { value: 'Three', label: 'Three' },
  ];

  const [SkillOption, setSkillOption] = useState(null);
  const SkillOptions = [
    { value: 'Skill 1', label: 'Skill 1' },
    { value: 'Skill 2', label: 'Skill 2' },
    { value: 'Skill 3', label: 'Skill 3' },
  ];

  const [LanguageOption, setLanguageOption] = useState(null);
  const LanguageOptions = [
    { value: 'Language 1', label: 'Language 1' },
    { value: 'Language 2', label: 'Language 2' },
    { value: 'Language 3', label: 'Language 3' },
  ];

  const [startDate, setStartDate] = useState(null);

  function SubmitPersonalData(e) {
    e.preventDefault();
    console.log('SubmitPersonalData', e);
  }

  return (
    <div className='container resumeforms personalform'>
      <Form onSubmit={SubmitPersonalData}>
        <Row className="mb-1">
          <Form.Group as={Col} xs="6" md="6">
            <Form.Label>First name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              placeholder=''
            />
          </Form.Group>
          <Form.Group as={Col} xs="6" md="6">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              placeholder=''
            />
          </Form.Group>
        </Row>
        <Row className="mb-1">
          <Form.Group as={Col} xs="12" md="12">
            <Form.Label>Profile Headline</Form.Label>
            <Form.Control
              type="text"
              name="ProfileHeadline"
              placeholder=''
            />
          </Form.Group>
        </Row>
        <Row className="mb-1">
          <Form.Group as={Col} xs="6" md="6">
            <Form.Label>Country</Form.Label>
            <Select
              defaultValue={CountryOption}
              onChange={setCountryOption}
              options={CountryOptions}
            />
          </Form.Group>
          <Form.Group as={Col} xs="6" md="6">
            <Form.Label>State</Form.Label>

            <Select
              defaultValue={StateOption}
              onChange={setStateOption}
              options={StateOptions}
            />
          </Form.Group>
        </Row>

        <Row className="mb-1">
          <Form.Group as={Col} xs="12" md="12">
            <Form.Label>City</Form.Label>
            <Select
              defaultValue={CityOption}
              onChange={setCityOption}
              options={CityOptions}
            />
          </Form.Group>
        </Row>

        <Row className="mb-1">
          <Form.Group as={Col} xs="12" md="12">
            <Form.Label>Email ID</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder=''
            />
          </Form.Group>
        </Row>

        <Row className="mb-1">
          <Form.Group as={Col} xs="4" md="4">
            <Form.Label>Country Code</Form.Label>
            <Select
              defaultValue={CountryCodeOption}
              onChange={setCountryCodeOption}
              options={CountryCodeOptions}
            />
          </Form.Group>
          <Form.Group as={Col} xs="8" md="8">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              type="text"
              name="mobileNumber"
              placeholder=''
            />
            
          </Form.Group>
        </Row>

        <Row className="mb-1">
          <Form.Group as={Col} xs="12" md="12">
            <Form.Label>Linkedin Profile Link</Form.Label>
            <Form.Control
              type="text"
              name="LinkedInProfileLink"
              placeholder=''
            />
          </Form.Group>
        </Row>

        <Row className="mb-1">
          <Form.Group as={Col} xs="12" md="12">
            <Form.Label>Gender</Form.Label>
            <div key={`inline-radio`} className="d-flex align-items-center gap-4 radioInputs">
              <Form.Check
                inline
                label="Male"
                name="group1"
                type='radio'
                defaultChecked
                id={`inline-radio-1`}
              />
              <Form.Check
                inline
                label="Female"
                name="group1"
                type='radio'
                id={`inline-radio-2`}
              />
              <Form.Check
                inline
                label="Other"
                name="group1"
                type='radio'
                id={`inline-radio-3`}
              />
            </div>
          </Form.Group>
        </Row>

        <Row className="mb-1">
          <Form.Group as={Col} xs="12" md="12">
            <Form.Label>Marital Status</Form.Label>
            <div key={`inline-radio`} className="d-flex align-items-center gap-4 radioInputs">
              <Form.Check
                inline
                defaultChecked
                label="Married"
                name="group2"
                type='radio'
                id={`inline-radio-4`}
              />
              <Form.Check
                inline
                label="Unmarried"
                name="group2"
                type='radio'
                id={`inline-radio-5`}
              />
            </div>
          </Form.Group>
        </Row>

        <Row className="mb-1">
          <Form.Group as={Col} xs="6" md="6">
            <Form.Label>Passport Available</Form.Label>
            <div key={`inline-radio`} className="d-flex align-items-center gap-4 radioInputs">
              <Form.Check
                inline
                label="Yes"
                name="group3"
                type='radio'
                defaultChecked
                id={`inline-radio-6`}
              />
              <Form.Check
                inline
                label="No"
                name="group3"
                type='radio'
                id={`inline-radio-7`}
              />
            </div>
          </Form.Group>

          <Form.Group as={Col} xs="6" md="6">
            <Form.Label>Passport Expiry</Form.Label>
            <DatePicker
              className="form-control"
              placeholderText="dd-MMM-yyyy"
              dateFormat="dd-MMM-yyyy"
              name="PassportExpiryDate"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
          />
          </Form.Group>
        </Row>

        <Row className="mb-1">
          <Form.Group as={Col} xs="12" md="12">
            <Form.Label>Nationality</Form.Label>
            <Select
              defaultValue={CountryOption}
              onChange={setCountryOption}
              options={CountryOptions}
            />
          </Form.Group>
        </Row>

        <Row className="mb-1">
          <Form.Group as={Col} xs="12" md="12">
            <Form.Label>Skills</Form.Label>
            <Select
              className='Multiselection'
              isMulti
              defaultValue={SkillOption}
              onChange={setSkillOption}
              options={SkillOptions}
            />
          </Form.Group>
        </Row>

        <Row className="mb-1">
          <Form.Group as={Col} xs="12" md="12">
            <Form.Label>Languages</Form.Label>
            <Select
              className='Multiselection'
              isMulti
              defaultValue={LanguageOption}
              onChange={setLanguageOption}
              options={LanguageOptions}
            />
          </Form.Group>
        </Row>

        <Row className="my-3 ">
          <Form.Group as={Col} xs="12" md="12">
            <div key={`reverse-check`} className="openfor">
              <Form.Check
                reverse
                label="Open for Work"
                type='checkbox'
                id={`reverse-check-1`}
              />
              <Form.Check
                reverse
                label="Open for Night Shift"
                type='checkbox'
                id={`reverse-check-2`}
              />
              <Form.Check
                reverse
                label="Open for Travel"
                type='checkbox'
                id={`reverse-check-3`}
              />
              <Form.Check
                reverse
                label="Open for Relocation"
                type='checkbox'
                id={`reverse-check-4`}
              />
            </div>
          </Form.Group>
        </Row>

        <Row className="mb-1">
          <Form.Group as={Col} xs="12" md="12">
            <Form.Label>PDF Resume</Form.Label>
            <div className="custom-file-upload" id="fileUpload1" style={{'height' : '120px'}}>
                <input type="file" id="fileuploadInput" accept=".pdf" />
                <label for="fileuploadInput">
                    <span>
                        <strong>
                            <i>Click here to upload PDF Resume</i>
                        </strong>
                    </span>
                </label>
            </div>
          </Form.Group>
        </Row>

        <Row className="mb-5">
          <Form.Group as={Col} xs="6" md="6">
            <Form.Label>Photo</Form.Label>
            <div className="custom-file-upload" id="fileUpload1" style={{'height' : '120px'}}>
                <input type="file" id="fileuploadInput" accept=".png, .jpg, .jpeg" />
                <label for="fileuploadInput">
                    <span>
                        <strong>
                            <i>Click here to upload an image</i>
                        </strong>
                    </span>
                </label>
            </div>
          </Form.Group>

          <Form.Group as={Col} xs="6" md="6">
            <Form.Label>Intro Video</Form.Label>
            <div className="custom-file-upload" id="fileUpload1" style={{'height' : '120px'}}>
                <input type="file" id="fileuploadInput" accept=".mp4" />
                <label for="fileuploadInput">
                    <span>
                        <strong>
                            <i>Click here to upload intro video</i>
                        </strong>
                    </span>
                </label>
            </div>
          </Form.Group>
        </Row>


        <div className="section full bg-white d-block d-md-none mobileMainFooter  position-fixed b-0 e-0 s-0" style={{zIndex: 100}}>
          <div className='d-flex gap-2 px-2 py-0 mt-1'>
            <Button variant='link' className='w-100' onClick={() => handleOpenConfirmModal()}>Exit</Button>
            <Button className='w-100'  onClick={() => {
              setSubmitStatus('waiting')
              navigat('/resume')
              }}>Save</Button>
          </div>
        </div>

        {ModalJSX("confirm-modal", {
        showCloseButton: true,
        children: (
            <div className="ModalAction">
                <Button variant="link" className='exitButton' onClick={() => closeModal("confirm-modal")}>
                    No
                </Button>
                <Button variant="primary" onClick={() => {
                    closeModal("confirm-modal");
                }}>
                    Yes
                </Button>
            </div>
            ),
        })}
      </Form>
    </div>
  )
}

const mapStateToProps = ({ resumeData }) => {
  const { activeResumeEdit } = resumeData;
  return {
    activeResumeEdit
  }
}

const mapDispatchToProps = {
  setActiveResumeEdit,
  setSubmitStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(Personal)