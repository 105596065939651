import React, { useState } from 'react'
import { Button, Col, Row, Form } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './EditResume.css'
import { connect } from 'react-redux'
import { setActiveResumeEdit, setSubmitStatus } from '../../../features/resumeEdit/resumeSlice'
import SvgIcon from '../../svg/SvgIcon';
import useModalComp from '../../Hooks/useModalComp'
import { useNavigate } from 'react-router';

export const Projects = ({ 
  activeResumeEdit,
  setActiveResumeEdit, 
  setSubmitStatus
}) => {

  const navigat = useNavigate();

  const { openModal, closeModal, ModalJSX } = useModalComp();
  
  const handleOpenConfirmModal = () => {
    openModal("confirm-modal", {
        ModalBodyContent: <div className='ConfirmationQuizModal'>
            <span className='mb-0 text-center'>
              The changes are not saved
              <br />
              <b>Are you Sure Want to Cancel?</b>
            </span>
        </div>,
    });
  };

  const [FromDate, setFromDate] = useState(null);
  const [ToDate, setToDate] = useState(null);


  const [OrganisationOption, setOrganisationOption] = useState(null);
  const OrganisationOptions = [
    { value: 'One', label: 'One' },
    { value: 'Two', label: 'Two' },
    { value: 'Three', label: 'Three' },
  ];

  const [ProjectDomainOption, setProjectDomainOption] = useState(null);
  const ProjectDomainOptions = [
    { value: 'One', label: 'One' },
    { value: 'Two', label: 'Two' },
    { value: 'Three', label: 'Three' },
  ];

  const [ProjectTypeOption, setProjectTypeOption] = useState(null);
  const ProjectTypeOptions = [
    { value: 'One', label: 'One' },
    { value: 'Two', label: 'Two' },
    { value: 'Three', label: 'Three' },
  ];

  const [ProjectPhaseInvolvedInOption, setProjectPhaseInvolvedInOption] = useState(null);
  const ProjectPhaseInvolvedInOptions = [
    { value: 'One', label: 'One' },
    { value: 'Two', label: 'Two' },
    { value: 'Three', label: 'Three' },
  ];

  const [WorkLocationTypeOption, setWorkLocationTypeOption] = useState(null);
  const WorkLocationTypeOptions = [
    { value: 'One', label: 'One' },
    { value: 'Two', label: 'Two' },
    { value: 'Three', label: 'Three' },
  ];

  const [ProductOption, setProductOption] = useState(null);
  const ProductOptions = [
    { value: 'One', label: 'One' },
    { value: 'Two', label: 'Two' },
    { value: 'Three', label: 'Three' },
  ];

  const [TrackOption, setTrackOption] = useState(null);
  const TrackOptions = [
    { value: 'One', label: 'One' },
    { value: 'Two', label: 'Two' },
    { value: 'Three', label: 'Three' },
  ];

  const [ModulesOption, setModulesOption] = useState(null);
  const ModulesOptions = [
    { value: 'One', label: 'One' },
    { value: 'Two', label: 'Two' },
    { value: 'Three', label: 'Three' },
  ];

  const [RolesOption, setRolesOption] = useState(null);
  const RolesOptions = [
    { value: 'One', label: 'One' },
    { value: 'Two', label: 'Two' },
    { value: 'Three', label: 'Three' },
  ];

  function SubmitPersonalData(e) {
    e.preventDefault();
    console.log('SubmitPersonalData', e);
  }

  return (
    <div className='container resumeforms projectforms'>
      <div className='activeFormButtons'>
        <div className='buttonContainer'>
          <Button variant='outline-primary'>
            Add New
            <SvgIcon icon='plus-circle-duotone' fill='#1165EF' size="24" />
          </Button>

          <Button variant='primary'>
            Project 1
          </Button>

          <Button variant='outline-primary'>
            Project 2
          </Button>

          <Button variant='outline-primary'>
            Project 3
          </Button>

          <Button variant='outline-primary'>
            Project 4
          </Button>

          <Button variant='outline-primary'>
            Project 5
          </Button>
        </div>
      </div>

      <div className='formContianer p-2 mt-1'>
        <div className='FormHeader mb-1'>
          <h3>ERP Project 1</h3>
          <Button variant='link' size='sm'>
            <SvgIcon icon='x' size="22" />
          </Button>
        </div>
        <Form onSubmit={SubmitPersonalData}>
          <Row className="mb-2">
            <Form.Group as={Col} xs="12" md="12">
              <Form.Label>Organisation*</Form.Label>
              <Select
                defaultValue={OrganisationOption}
                onChange={setOrganisationOption}
                options={OrganisationOptions}
              />
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} xs="12" md="12">
              <Form.Label>Project Name*</Form.Label>
              <Form.Control
                type="text"
                name="projectName"
                placeholder=''
              />
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} xs="12" md="12">
              <Form.Label>Project Domain/ Industry*</Form.Label>
              <Select
                defaultValue={ProjectDomainOption}
                onChange={setProjectDomainOption}
                options={ProjectDomainOptions}
              />
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} xs="12" md="12">
              <Form.Label>Project Type*</Form.Label>
              <Select
                defaultValue={ProjectTypeOption}
                onChange={setProjectTypeOption}
                options={ProjectTypeOptions}
              />
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} xs="12" md="12">
              <Form.Label>Project Phases Involved In*</Form.Label>
              <Select
                isMulti
                defaultValue={ProjectPhaseInvolvedInOption}
                onChange={setProjectPhaseInvolvedInOption}
                options={ProjectPhaseInvolvedInOptions}
              />
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group className="mb-1 openfor">
              <Form.Check
                reverse
                label="Currently working on this project"
                type='checkbox'
                id={`reverse-check-1`}
              />
            </Form.Group>
          </Row>


          <Row className="mb-2">
            <Form.Group as={Col} xs="6" md="6">
              <Form.Label>From Date*</Form.Label>
              <DatePicker
                className="form-control w-100"
                placeholderText="dd-MMM-yyyy"
                dateFormat="dd-MMM-yyyy"
                name="FromDate"
                selected={FromDate}
                onChange={(date) => setFromDate(date)}
            />
            </Form.Group>
            <Form.Group as={Col} xs="6" md="6">
              <Form.Label>To Date*</Form.Label>
              <DatePicker
                className="form-control w-100"
                placeholderText="dd-MMM-yyyy"
                dateFormat="dd-MMM-yyyy"
                name="ToDate"
                selected={ToDate}
                onChange={(date) => setToDate(date)}
            />
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} xs="12" md="12">
              <Form.Label>Work Location Type</Form.Label>
              <Select
                defaultValue={WorkLocationTypeOption}
                onChange={setWorkLocationTypeOption}
                options={WorkLocationTypeOptions}
              />
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} xs="12" md="12">
              <Form.Label>Product*</Form.Label>
              <Select
                defaultValue={ProductOption}
                onChange={setProductOption}
                options={ProductOptions}
              />
            </Form.Group>
          </Row>


          <Row className="mb-2">
            <Form.Group as={Col} xs="12" md="12">
              <Form.Label>Track*</Form.Label>
              <Select
                defaultValue={TrackOption}
                onChange={setTrackOption}
                options={TrackOptions}
              />
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} xs="12" md="12">
              <Form.Label>Modules*</Form.Label>
              <Select
                isMulti
                defaultValue={ModulesOption}
                onChange={setModulesOption}
                options={ModulesOptions}
              />
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} xs="12" md="12">
              <Form.Label>Roles*</Form.Label>
              <Select
                isMulti
                defaultValue={RolesOption}
                onChange={setRolesOption}
                options={RolesOptions}
              />
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} xs="12" md="12">
              <Form.Label>Key Contributions</Form.Label>
              <Form.Control
                as="textarea"
                name="KeyContributions"
                rows="4"
                placeholder=''
              />
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} xs="12" md="12">
              <Form.Label>Recognitions</Form.Label>
              <Form.Control
                as="textarea"
                name="Recognitions"
                rows="4"
                placeholder=''
              />
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} xs="12" md="12">
              <Form.Label>Key Learnings</Form.Label>
              <Form.Control
                as="textarea"
                name="KeyLearnings"
                rows="4"
                placeholder=''
              />
            </Form.Group>
          </Row>


          <div className="section full bg-white d-block d-md-none mobileMainFooter  position-fixed b-0 e-0 s-0" style={{zIndex: 100}}>
            <div className='d-flex gap-2 px-2 py-0 mt-1'>
              <Button variant='link' className='w-100' onClick={() => handleOpenConfirmModal()}>Exit</Button>
              <Button className='w-100' onClick={() => {
                setSubmitStatus('waiting');
                navigat('/resume')
              }
              }>Save</Button>
            </div>
          </div>
        </Form>
      </div>
      <div className='AddNextButton'>
        <Button variant='ouline-primary'>
          Add New Project
          <SvgIcon icon='plus-circle-duotone' fill='#1165EF' size="24" />
        </Button>
      </div>

      {ModalJSX("confirm-modal", {
                showCloseButton: true,
                children: (
                    <div className="ModalAction">
                        <Button variant="link" className='exitButton' onClick={() => closeModal("confirm-modal")}>
                            No
                        </Button>
                        <Button variant="primary" onClick={() => {
                            closeModal("confirm-modal");
                        }}>
                            Yes
                        </Button>
                    </div>
                ),
            })}
    </div>
  )
}

const mapStateToProps = ({ resumeData }) => {
  const { activeResumeEdit } = resumeData;
  return {
    activeResumeEdit
  }
}

const mapDispatchToProps = {
  setActiveResumeEdit,
  setSubmitStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects)