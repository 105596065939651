import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeResumeEdit: '1',
  submitStatus : null,
}

export const resumeSlice = createSlice({
  name: 'resumeData',
  initialState,
  reducers: {
    setActiveResumeEdit: (state, action) => {
      state.activeResumeEdit = action.payload
    },
    setSubmitStatus: (state, action) => {
      state.submitStatus = action.payload
    }
  }
})

export const { setActiveResumeEdit, setSubmitStatus } = resumeSlice.actions;


export default resumeSlice.reducer
