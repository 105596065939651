import React, { useState, useEffect } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import SvgIcon from '../../svg/SvgIcon';
import { Messages } from '../../../config';
import { isValidEmail } from '../../../features/auth/authSlice';
import useStateForLogin from '../../Hooks/useSatesForLogin';
import { useNavigate } from 'react-router';

function EnterMobile({ isVerifyPageActive, setVerifyPage }) {
    const navigate = useNavigate();
    const formInputes = {
        'email': null,
        'otp' : null
    }
    const { values, UpdateValues, errors, UpdateErrors } = useStateForLogin(formInputes, formInputes);

    const [validated, setValidated] = useState(false);

    const [isEmail, setIsEmail] = useState(null);

    useEffect(() => {
        if(values.email?.trim().length === 0) {
            setIsEmail(null);
        } else if(values.email && isNaN(Number(values.email?.trim()))) {
            setIsEmail(true);
        } else if(values.email) {
            setIsEmail(false);
        }

        
        
        if(validated) {
            if(errors.email === Messages.EmailMobileRequired && values.email?.length > 0) {
                UpdateErrors('email', null);
            } else if (errors.email === Messages.ValidEmail && isValidEmail(values.email)) {
                UpdateErrors('email', null);
            } else if (errors.email === Messages.ValidMobile && values.email?.trim().length === 10) {
                UpdateErrors('email', null);
            }

            if (errors.otp === Messages.OTPRequired && values.otp.trim().length > 0) {
                UpdateErrors('otp', null);
            } else if(errors.otp === Messages.OTPLength && values.otp.trim().length === 6){
                UpdateErrors('otp', null);
            }
        }
    }, [values])
    

    const handleEmailMobileChange = (text) => {
        UpdateValues("email", text);
    }

    const handleSendOTPSubmit = (event) => {
        event.preventDefault();
        if(values.email === null || values.email.trim().length === 0) {
            setValidated(true);
            UpdateErrors("email", Messages.EmailMobileRequired)
            return 
        }
        if(isEmail && !isValidEmail(values.email)) {
            setValidated(true);
            UpdateErrors("email", Messages.ValidEmail)
            return
        }
        if(values.email.length !== 10) {
            setValidated(true);
            UpdateErrors("email", Messages.ValidMobile);
            return
        }
        setVerifyPage(true);
        setValidated(false);
    };

    const handleOtpChange = (text) => {
        UpdateValues("otp", text);
    }

    const handleVerifyOTPSubmit = (event) => {
        event.preventDefault();

        // if(values.otp === null || values.otp.trim().length === 0) {
        //     setValidated(true);
        //     UpdateErrors("otp", Messages.OTPRequired)
        //     return 
        // }

        // if(values.otp.length !== 6) {
        //     setValidated(true);
        //     UpdateErrors("otp", Messages.OTPLength);
        //     return
        // }

        navigate('/register');
    }

    return (
        <>
            <Form className={isVerifyPageActive ? 'd-none' : 'LoginForm'} onSubmit={handleSendOTPSubmit}>
                <Form.Group className='position-relative'>
                    
                    {errors.email &&
                            <div className='badge bg-danger position-absolute error-badge t-n3 e-0'>
                                {errors.email}
                            </div>
                    }
                    <div className={isEmail !== null ? 'showIcon inputContainer': 'inputContainer'}>
                        {
                            isEmail === true &&
                            <span className='Email'><SvgIcon icon="email" /></span>
                        }
                        {
                            isEmail === false &&
                            <span className='CountryCode'>+91</span>
                        }
                        <Form.Control
                            type={"text"}
                            name="email"
                            value={values.email}
                            placeholder="Enter email or mobile number"
                            onChange={(e) => handleEmailMobileChange(e.target.value)}
                        />
                    </div>
                        
                </Form.Group>
                <Button type="submit">Send OTP</Button>
            </Form>

            
            { isVerifyPageActive &&
                    <p className='text-center my-3 OTPSentOn'>
                        <span>Enter the OTP sent to {isEmail === false && '+91 '}{values.email}</span>
                         <Button variant='link' size='sm' onClick={() => setVerifyPage(false)}>edit <SvgIcon icon='pencilSimpleLine' fill='#1165EF' size="16" /></Button>
                    </p>
                }
            <Form className={isVerifyPageActive ? 'VerifyForm' : 'd-none'} onSubmit={handleVerifyOTPSubmit}>
                <Form.Group className='position-relative'>
                    
                    {errors.otp &&
                            <div className='badge bg-danger position-absolute error-badge t-n3 e-0'>
                                {errors.otp}
                            </div>
                    }
                    <div className='inputContainer otpinputs'>
                        <Form.Control
                            type="text"
                            name="otp"
                            // value={values.otp}
                            placeholder=""
                            // onChange={(e) => handleOtpChange(e.target.value)}
                        />
                        <Form.Control
                            type="text"
                            name="otp"
                            // value={values.otp}
                            placeholder=""
                            // onChange={(e) => handleOtpChange(e.target.value)}
                        />
                        <Form.Control
                            type="text"
                            name="otp"
                            // value={values.otp}
                            placeholder=""
                            // onChange={(e) => handleOtpChange(e.target.value)}
                        />
                        <Form.Control
                            type="text"
                            name="otp"
                            // value={values.otp}
                            placeholder=""
                            // onChange={(e) => handleOtpChange(e.target.value)}
                        />
                        <Form.Control
                            type="text"
                            name="otp"
                            // value={values.otp}
                            placeholder=""
                            // onChange={(e) => handleOtpChange(e.target.value)}
                        />
                        <Form.Control
                            type="text"
                            name="otp"
                            // value={values.otp}
                            placeholder=""
                            // onChange={(e) => handleOtpChange(e.target.value)}
                        />
                    </div>
                        
                </Form.Group>
                <div className='d-flex gap-2 mt-2'>
                    <Button type="button" variant='outline-primary' className='exitButton w-100' onClick={() => setVerifyPage(false)}>Resend OTP</Button>
                    <Button type="submit" className='w-100'>Submit OTP</Button>
                </div>
            </Form>
        </>
    )
}

export default EnterMobile